import { VUser } from "model/VUser";
import * as ApiActions from "./apiCaller";
import { config } from "config";
import { Permission } from "model/Permission";

export function GetPermissions(
  pagingParameter: any,
  searchCriterias: any,
  handleSuccess: any,
  handleError: any
) {
  var isAsc = "true";
  if (!pagingParameter.isAsc) isAsc = "false";
  var baseUrl =
    config.REACT_APP_BASE_API_URL +
    "Users?StartPage=" +
    pagingParameter.currentPage +
    "&Count=" +
    pagingParameter.pageSize +
    "&OrderField=" +
    pagingParameter.currentSortId +
    "&IsAsc=" +
    pagingParameter.isAsc;
  if (searchCriterias !== null) {
    if (
      searchCriterias.freeSearch != null &&
      searchCriterias.freeSearch != undefined
    )
      baseUrl += "&freeSearch=" + searchCriterias.freeSearch;
    if (
      searchCriterias.firstName != null &&
      searchCriterias.firstName != undefined
    )
      baseUrl += "&FirstName=" + searchCriterias.firstName;
    if (
      searchCriterias.lastName != null &&
      searchCriterias.lastName != undefined
    )
      baseUrl += "&LastName=" + searchCriterias.lastName;
    if (searchCriterias.email != null && searchCriterias.email != undefined)
      baseUrl += "&Email=" + searchCriterias.email;
    if (searchCriterias.sjId != null && searchCriterias.sjId != undefined)
      baseUrl += "&Sj=" + searchCriterias.sjId;
  }

  ApiActions.getAPI(baseUrl)
    .then((datas) => {
      if (handleSuccess != null && handleSuccess != undefined)
        handleSuccess(datas.data);
    })
    .catch((error) => {
      var errorMessage = "Une erreur s'est produite.";
      console.error(error);
    });
}

export async function PostPermission(permission: any) {
  var baseUrl = config.REACT_APP_BASE_API_URL + "Users";
  await ApiActions.postAPI(baseUrl, permission);
}

export function convertVuserToPermission(vuser: VUser) {
  const permission = new Permission();
  if (vuser) {
    permission.id = vuser.id || 0;
    permission.userId = vuser.email || "";
    permission.sjId = vuser.sj || "";
    permission.clientId = vuser.customerMdmId || 0;
    permission.roleId = vuser.roleId || 0;
    permission.isActive = vuser.isActive || false;
  }
  return permission;
}

export function convertPermissionToVuser(permission: Permission) {
  const vuser = new VUser();
  if (permission) {
    vuser.id = permission.id || 0;
    vuser.email = permission.userId || "";
    vuser.sj = permission.sjId || "";
    vuser.customerMdmId = permission.clientId || 0;
    vuser.roleId = permission.roleId || 0;
    vuser.isActive = permission.isActive || false;
  }
  return vuser;
}
