import { generateUUID } from "tools/uuid";

// create a new ManagedCorporation class
export class ManagedCorporation {
    // define the properties of the class
    id!: string | null;
    // define the properties of the class
    corporationName!: string;
    corporationDisplayName!: string;
    corporationFullName!: string;
    corporationAddress!: string;
    corporationCapital!: string;
    corporationIntracom!: string;
    corporationRcs!: string;
    corporationContact!: string;
    synchronizationFilter!: string;
    status!: number;
    workflowId!: string;
    sjCode!: string;
    b2cFileName!: string;
    b2cUrl!: string;
    logoFileName!: string;
    logoUrl!: string;
    iconFileName!: string;
    iconUrl!: string;
    primaryColor!: string;
    b2cImage!: File;
    logoImage!: File;
    imageproperty!: string;
    urlProduction!: string;
    latitude!: string;
    longitude!: string;

    constructor() {
        this.primaryColor = "#ffffff";
        this.b2cUrl = "images/no-content.png";
        this.logoUrl = "images/no-content.png";
        this.iconUrl = "images/no-content.png";
    }
}