import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

export function GetAll(handleLoadComplete, handleError) {
    var baseUrlForRoles = config.REACT_APP_BASE_API_URL + "MasterDatas/Roles";
    var baseUrlForSjs = config.REACT_APP_BASE_API_URL + "MasterDatas/Sjs";
    var baseUrlForPortalSjs = config.REACT_APP_BASE_API_URL + "MasterDatas/PortalSjs";
    var baseUrlForUes = config.REACT_APP_BASE_API_URL + "MasterDatas/Ues";
    var baseUrlForUps = config.REACT_APP_BASE_API_URL + "MasterDatas/Ups";
    var baseUrlForActivities = config.REACT_APP_BASE_API_URL + "MasterDatas/Activities";
    var baseUrlForWastes = config.REACT_APP_BASE_API_URL + "MasterDatas/Wastes";

    axios.all([ApiActions.getAxiosrequest(baseUrlForRoles),
    ApiActions.getAxiosrequest(baseUrlForSjs),
    ApiActions.getAxiosrequest(baseUrlForPortalSjs),
    ApiActions.getAxiosrequest(baseUrlForUes),
    ApiActions.getAxiosrequest(baseUrlForUps),
    ApiActions.getAxiosrequest(baseUrlForActivities),
    ApiActions.getAxiosrequest(baseUrlForWastes)]).then(axios.spread((...responses) => {
        const roles = responses[0].data;
        const sjs = responses[1].data;
        const portalSjs = responses[2].data;
        const ues = responses[3].data;
        const ups = responses[4].data;
        const activities = responses[5].data;
        const wastes = responses[6].data;
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("portalSjs", JSON.stringify(portalSjs));
        localStorage.setItem("sjs", JSON.stringify(sjs));
        localStorage.setItem("ues", JSON.stringify(ues));
        localStorage.setItem("ups", JSON.stringify(ups));
        localStorage.setItem("activities", JSON.stringify(activities));
        localStorage.setItem("wastes", JSON.stringify(wastes));
        handleLoadComplete();
        // use/access the results 
    })).catch(errors => {
        handleError(errors);
    })
}