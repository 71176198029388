import { DialogContent } from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { CustomerSearchCriteria } from "./customerSearchCriteria";
import { Close } from "@mui/icons-material";
import WaitingComponent from "components/common/waitingComponent";
import { CustomerList } from "./customerList";
import useWindowDimensions from "services/windowDimensions";
import { useEffect, useState } from "react";
import { VCustomer } from "model/VCustomer";
import { GetVCustomers, PostCustomer, convertCustomerToVcustomer, convertVcustomerToCustomer } from "services/customerApiCaller";
import "style/customerStyle.scss";
import { CustomerToolBar } from "./customerToolBar";
import { CustomerDetail } from "./customerDetail";

interface CustomerDialogProps {
  sjCode: string;
  open: boolean;
  handleClose: () => void;
}

export default function CustomerListDialog({
  sjCode,
  open,
  handleClose,
}: CustomerDialogProps) {
  var dim = useWindowDimensions();
  var pageSize = 15;
  if (dim.height > 900) pageSize = 17;

  const [snackBarProperties, setSnackBarProperties] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [criterias, setCriterias] = useState({
    sjCode: sjCode,
    isComplexSearch: false,
    freeSearch: "",
  });
  const [pagingParameters, setPagingParameters] = useState({
    currentPage: 0,
    pageSize: pageSize,
    currentSortId: "cl.id",
    isAsc: false,
  });
  const [datas, setDatas] = useState([]);
  const [layoutAndDetailProperties, setLayoutAndDetailProperties] =
    useState<any>({
      listPartWidth: 90,
      detailPartWidth: 0,
      selectedItem: null as VCustomer | null,
    });

  useEffect(() => {
    handleSearch();
  }, [pagingParameters]);

  function handleSearch() {
    setIsLoading(true);
    GetVCustomers(pagingParameters, criterias)
      .then((datas: any) => {
        setDatas(datas.data);
        setIsLoading(false);
      })
      .catch((exc: any) => {
        console.log(exc);
        setIsLoading(false);
      });
  }

  function detailChanged(item: any) {
    setLayoutAndDetailProperties({
      ...layoutAndDetailProperties,
      selectedItem: convertCustomerToVcustomer(item),
    });
  }
  
  function handleSave() {
    const customer = convertVcustomerToCustomer(
      layoutAndDetailProperties.selectedItem
    );
    PostCustomer(customer)
      .then(() => {
        setSnackBarProperties({
          isOpen: true,
          message: "Client enregistré",
          severity: "success",
        });
        handleSearch();
      })
      .catch((error: any) => {
        setSnackBarProperties({
          isOpen: true,
          message: error.response.data.detail,
          severity: "error",
        });
        console.log(error);
      });
  }

  function handlePageChange(param: any) {
    setPagingParameters(param);
  }

  function adjustLayout(item: any) {
    if (item)
      setLayoutAndDetailProperties({
        listPartWidth: 60,
        detailPartWidth: 40,
        selectedItem: item,
      });
    else
      setLayoutAndDetailProperties({
        listPartWidth: 90,
        detailPartWidth: 0,
        selectedItem: item,
      });
  }

  function handleImport() {
    console.log("handleImport");
  }

  function handleExport() {
    console.log("handleExport");
  }

  function changeCriterias(key: string, value: any) {
    setCriterias({ ...criterias, [key]: value });
  }

  function getDetail() {
    if (layoutAndDetailProperties.selectedItem)
      return (
        <CustomerDetail
          item={convertVcustomerToCustomer(
            layoutAndDetailProperties.selectedItem
          )}
          handleChange={detailChanged}
          handleSave={handleSave}
          handleCancel={() =>
            setLayoutAndDetailProperties({
              detailPartWidth: 0,
              listPartWidth: 90,
              selectedItem: null,
            })
          }
        />
      );
    else return <></>;
  }

  function getContent() {
    if (isLoading) {
      return <WaitingComponent message={"Clients en cours de chargement"} />;
    } else {
      return (
        <>
          <div
            className="customerListPart"
            style={{ width: layoutAndDetailProperties.listPartWidth + "%" }}
          >
            <CustomerToolBar
              handleSearch={handleSearch}
              handleImport={handleImport}
              handleExport={handleExport}
              changeCriterias={changeCriterias}
              criterias={criterias}
            />
            <CustomerList
              items={datas}
              pagingParameter={pagingParameters}
              pageChangingHandler={(event: any, param: any) =>
                handlePageChange(param)
              }
              onRowSelected={adjustLayout}
              selectedItem={layoutAndDetailProperties.selectedItem}
              setSelectedItem={(item: any) => {
                setLayoutAndDetailProperties({
                  ...layoutAndDetailProperties,
                  selectedItem: item,
                });
              }}
            />
          </div>
          <div
            className="userDetailPart"
            style={{
              width: layoutAndDetailProperties.detailPartWidth + "%",
              visibility:
                layoutAndDetailProperties.detailPartWidth == 0
                  ? "hidden"
                  : "visible",
            }}
          >
          {getDetail()}
          </div>
        </>
      );
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="dialogCustomer"
      disableEnforceFocus
    >
      <Toolbar className="toolbarCustomer">
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Liste des clients de la société {sjCode}
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Toolbar>
      <div className="customerDialogContent">{getContent()}</div>
    </Dialog>
  );
}
