import 'style/common.scss';

interface CustomInputProps {
  label: string;
  value: boolean;
  handleChange: any;
  classname?: string;
}
export function CustomCheckbox(props: CustomInputProps) {
  return (
    <div className={"customInputContainer " + (props.classname ?? "")}>
      <div className="customInputLabel">
        <label>{props.label}</label>
      </div>
      <div >
        <input
          type="checkbox"
          checked={props.value}
          onChange={props.handleChange}
        />
      </div>
    </div>
  );
}
