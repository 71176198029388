import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "@reach/router";
import PlaceIcon from '@mui/icons-material/Place';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { Box, Card, CardContent, Drawer, IconButton, Toolbar } from "@mui/material";
import { config } from "config";
import SideBarItem from "components/common/sideBarItem";
import 'style/layout.scss';
import { Language, PlaceOutlined } from "@mui/icons-material";

const drawerWidth = 150;

const isActive = ({ isCurrent }: any) => {
    return isCurrent
        ? { className: "active nav-link" }
        : { className: "nav-link" };
};
// `getProps` Calls up to you to get props for the underlying anchor element.
// Useful for styling the anchor as active.
const ExactNavLink = (props: any) => <Link getProps={isActive} {...props} />;

function SideBar({ authentReducer }: any) {

    return (
        <>
            <Drawer variant="permanent"
                className="sidebar">
                <Toolbar />
                <SideBarItem link="/Home" text="Portails" >
                    <Language fontSize="medium" />
                </SideBarItem>
                <SideBarItem link="/Dashboard" text="Dashboard" >
                    <DashboardIcon fontSize="medium" />
                </SideBarItem>
            </Drawer>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return state;
};

export default connect(mapStateToProps)(SideBar);
