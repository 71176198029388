import { VUser } from "model/VUser";
import * as ApiActions from "./apiCaller";
import { config } from "config";
import { Permission } from "model/Permission";
import { VCustomer } from "model/VCustomer";
import { Customer } from "model/Customer";

export async function GetVCustomers(
  pagingParameter: any,
  searchCriterias: any
) {
  var isAsc = "true";
  if (!pagingParameter.isAsc) isAsc = "false";
  var baseUrl =
    config.REACT_APP_BASE_API_URL +
    "Customers?StartPage=" +
    pagingParameter.currentPage +
    "&Count=" +
    pagingParameter.pageSize +
    "&OrderField=" +
    pagingParameter.currentSortId +
    "&IsAsc=" +
    pagingParameter.isAsc;
  if (searchCriterias) {
    if (searchCriterias.freeSearch)
      baseUrl += "&freeSearch=" + searchCriterias.freeSearch;
    if (searchCriterias.status)
      baseUrl += "&Status=" + searchCriterias.status;
    if (searchCriterias.name)
      baseUrl += "&Name=" + searchCriterias.name;
    if (searchCriterias.sjCode)
      baseUrl += "&SjCode=" + searchCriterias.sjCode;
    if (searchCriterias.isActive)
      baseUrl += "&IsActive=" + searchCriterias.isActive;
    if (searchCriterias.isInternal)
      baseUrl += "&IsInternal=" + searchCriterias.isInternal;
  }

  return await ApiActions.getAPI(baseUrl);
}

export async function PostCustomer(customer: any) {
  var baseUrl = config.REACT_APP_BASE_API_URL + "Customers";
  await ApiActions.postAPI(baseUrl, customer);
}

export function convertVcustomerToCustomer(vcustomer: VCustomer) {
  const customer = new Customer();
  if (vcustomer) {
    customer.id = vcustomer.id || 0;
    customer.name = vcustomer.name || "";
    customer.siret = vcustomer.siret || "";
    customer.isActive = vcustomer.isActive || false;
  }
  return customer;
}

export function convertCustomerToVcustomer(customer: Customer) {
  const vcustomer = new VCustomer();
  if (customer) {
    vcustomer.id = customer.id || 0;
    vcustomer.name = customer.name || "";
    vcustomer.siret = customer.siret || "";
    vcustomer.isActive = customer.isActive || false;
  }
  return vcustomer;
}
