import { DoneOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";

const workflowTaskTypes = [
  { label: "Ajout des images au CDN du B2C", id: 0 },
  { label: "Ajout des app registrations", id: 1 },
  { label: "Ajout de la Sj dans la configuration du B2C", id: 2 },
  // { label: "AddB2cManagement", id: 3 },
  { label: "Mise à jour de la librairie ADO pour la CI/CD", id: 4 },
  // { label: "AddSiteLibrary", id: 5 },
  { label: "Ajout des fichiers RGPD", id: 6 },
  // { label: "AddReleaseTask", id: 7 },
];

const environnements = [
  { label: "dev", id: "dev" },
  { label: "rec", id: "rec" },
  { label: "prd", id: "prd" },
];

interface ManagedCorporationIntegrationLauncherProps {
  validationHandle: any;
}

export function ManagedCorporationIntegrationLauncher({validationHandle}: ManagedCorporationIntegrationLauncherProps) {
  var [selectedWorkflowTypes, setSelectedWorkflowTypes] = useState<any>([]);
  var [selectedEnvironnements, getselectedEnvironnements] = useState<any>([]);

  function handleWorkflowTypeChange(event: any) {
    var workflowType: any = selectedWorkflowTypes.find(
      (item: any) => item === event.target.id
    );
    if (workflowType){
      setSelectedWorkflowTypes(
        selectedWorkflowTypes.filter((item: any) => item !== event.target.id)
      );
    } 
    else{
      setSelectedWorkflowTypes([...selectedWorkflowTypes, event.target.id]);
    }
  }

  function handleEnvironnementChange(event: any) {
    var environnement: any = selectedEnvironnements.find(
      (item: any) => item === event.target.id
    );
    if (environnement){
      getselectedEnvironnements(
        selectedEnvironnements.filter((item: any) => item !== event.target.id)
      );
    } 
    else{
      getselectedEnvironnements([...selectedEnvironnements, event.target.id]);
    }
  }

  function showEnvironmentsCheckArray() {
    return environnements.map((environment: any) => {
      return (
        <div>
          <input
            type="checkbox"
            key={"chk" + environment.id}
            id={environment.id}
            name={environment.id}
            onClick={handleEnvironnementChange}
          />
          <label key={"lbl" + environment.id} htmlFor={environment.label}>
            {"(" + environment.id + ") " + environment.label}
          </label>
        </div>
      );
    });
  }
  function showWorkflowTypesCheckArray() {
    return workflowTaskTypes.map((workflowType: any) => {
      return (
        <div>
          <input
            type="checkbox"
            key={"chk" + workflowType.id}
            id={workflowType.id}
            name={workflowType.id}
            onClick={handleWorkflowTypeChange}
          />
          <label key={"lbl" + workflowType.id} htmlFor={workflowType.label}>
            {workflowType.label}
          </label>
        </div>
      );
    });
  }

  return (
    <div style={{ width: "400px", padding: "5px" }}>
      <h3>Environnements cibles</h3>
      <div style={{marginLeft: "10px"}}>{showEnvironmentsCheckArray()}</div>
      <h3>Actions à réaliser</h3>
      <div style={{marginLeft: "10px"}}>{showWorkflowTypesCheckArray()}</div>
      <div style={{width: "100%", justifyContent: "center", display: "flex", marginTop: "10px"}}>
      <Button
            style={{
              backgroundColor: "black",
              color: "white",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
            endIcon={<DoneOutline />}
            onClick={() => validationHandle({environnements: selectedEnvironnements, workflowTypes: selectedWorkflowTypes})}
          >
            Valider
          </Button>
      </div>
    </div>
  );
}
