// create a new component called WorkflowTaskList

import { WorkflowTask } from "model/WorkflowTask";
import { WorkflowTaskItem } from "./workflowTaskItem";

interface WorkflowTaskListProps {
  workflowTasks: WorkflowTask[];
}

export function WorkflowTaskList(props: WorkflowTaskListProps) {

  function buildList(){
    return props.workflowTasks.map((workflowTask) => {
        return(
        <WorkflowTaskItem workflowTask={workflowTask} />
        );
    });
  }

  return (
    <div className="workflowTaskListContainer">
      {props.workflowTasks.length > 0 ? buildList() : (<div>Aucune tâche trouvée</div>)}
    </div>
  );
}
