import { Box } from "@mui/material";
import { Redirect } from "@reach/router";
import React, { useState, useRef, useEffect, ReactElement } from "react";
import * as Actions from "../services/masterDataApiCaller";
import { connect } from "react-redux";
import CircleLoader from "react-spinners/CircleLoader";

import 'style/startup.scss';


const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function Startup({ isAuthenticated, ...rest }: any) {

    const [cacheReady, setCacheReady] = useState(false);


    function handleLoadComplete() {
        setCacheReady(true);
    }
    function handleError(errors: any) {

    }

    function GetAuthentComponent() {
        if (isAuthenticated) {
            if (!cacheReady) {
                Actions.GetAll(handleLoadComplete, handleError);
                return (
                    <span
                        className="typography"
                    >Master datas loading...</span>
                );
            }
            else {
                return (
                    <Redirect to="/Home" noThrow />
                );
            }
        }

        return (
            <span className="typography">Authentification pending...</span>
        );
    }



    return (
        <Box
            className="box"
        >
            <CircleLoader color="white" loading={true} cssOverride={override} size={150} />
            {GetAuthentComponent()}
        </Box>
    );

}

const mapStateToProps = (state:any) => {
    return {
        isAuthenticated: state.authentReducer.isAuthenticated
    };
};


export default connect(mapStateToProps)(Startup);