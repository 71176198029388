import { CustomInput } from "components/common/customInput";
import { SiteDetailProps } from "./siteDetail";

export function SiteDetail_Structure(props: SiteDetailProps) {
    return (
      <>
      <div className="titleForm">Structure de rattachement</div>
        <div className="detailFormContainer">
          <CustomInput
            label="SJ"
            value={props?.item?.sj || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "sj")
            }
          />
          <CustomInput
            label="UE"
            value={props?.item?.ue || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "ue")
            }
          />
          <CustomInput
            label="UP"
            value={props?.item?.up || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "up")
            }
          />
        </div>
      </>
    );
}