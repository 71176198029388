import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { ColumnOption } from "model/ColumnOption";
import { useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import useWindowDimensions from "services/windowDimensions";
import "style/tableResultDefaultStyle.scss";

interface TableResultProps {
  headers: ColumnOption[];
  items: any;
  count: number;
  pagingParam: any;
  onPageChange: any;
  onRowSelected?: any;
  idProperty: string;
  selectedItem?: any;
  setSelectedItem?: any;
}

export default function TableResult(props: TableResultProps) {
  var dim = useWindowDimensions();
  var maxHeight = 600;
  if (dim.height > 900) maxHeight = 800;

  function selectRow(item: any) {
    if (
      props.selectedItem &&
      props.idProperty &&
      props.selectedItem[props.idProperty] === item[props.idProperty]
    ) {
      props.setSelectedItem(null);
      if (props.onRowSelected) props.onRowSelected(null);
    } else {
      props.setSelectedItem(item);
      if (props.onRowSelected) props.onRowSelected(item);
    }
  }

  function isSelected(item: any) {
    if (props.selectedItem === null || props.selectedItem === undefined) return false;
    if (!props.idProperty) return false;
    return props.selectedItem[props.idProperty] === item[props.idProperty];
  }

  if (props.items === null || props.items === undefined) return <></>;
  else
    return (
      <Box>
        <TableContainer
          sx={{
            maxHeight: { maxHeight },
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow key="header">
                {props.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.itemProperty}
                      className="defaultHeaderCell"
                      sortDirection={props.pagingParam.isAsc ? "asc" : "desc"}
                    >
                      <TableSortLabel
                        active={
                          props.pagingParam.currentSortId === header.orderField
                        }
                        direction={props.pagingParam.isAsc ? "asc" : "desc"}
                        onClick={(event) => {
                          var isAsc = true;
                          if (
                            props.pagingParam.currentSortId ===
                            header.orderField
                          ) {
                            isAsc = !props.pagingParam.isAsc;
                          }
                          props.onPageChange(event, {
                            ...props.pagingParam,
                            currentSortId: header.orderField,
                            isAsc: isAsc,
                          });
                        }}
                      >
                        {header.headerText}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.items.map((item: any) => {
                return (
                  <TableRow
                  style={{cursor: "pointer"}}
                    key={"row" + item[props.idProperty]}
                    selected={isSelected(item)}
                    onClick={(e) => selectRow(item)}
                  >
                    {props.headers.map((header) => {
                      if (header.formatHandler == null)
                        return (
                          <TableCell key={header.itemProperty + item[props.idProperty]}>{item[header.itemProperty]}</TableCell>
                        );
                      else
                        return (
                          <TableCell key={header.itemProperty + item[props.idProperty]}>{header.formatHandler(item)}</TableCell>
                        );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[props.pagingParam.pageSize]}
          component="div"
          count={props.count}
          rowsPerPage={props.pagingParam.pageSize}
          page={props.pagingParam.currentPage}
          onPageChange={(event, newPage) => {
            props.onPageChange(event, {
              ...props.pagingParam,
              currentPage: newPage,
            });
          }}
          onRowsPerPageChange={(event) => {
            props.onPageChange(event, {
              ...props.pagingParam,
              pageSize: event.target.value,
            });
          }}
        />
      </Box>
    );
}
