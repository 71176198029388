export class Permission{
    id: Nullable<number>;
    userId: Nullable<string>;
    sjId: Nullable<string>;
    clientId: Nullable<number>;
    roleId: number;
    isActive?: Nullable<boolean>;

    constructor(id: number = 0, userId: Nullable<string> = null, sjId: Nullable<string>= null, clientId: Nullable<number> = null, roleId: number = 2){
        this.id = id;
        this.userId = userId;
        this.sjId = sjId;
        this.clientId = clientId;
        this.roleId = roleId;
    }
}