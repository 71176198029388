import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

export function SetIdToken(token: any){
    return (dispatch: any) => {
        
        dispatch({
            type: AuthenticationActions.AcquiredIdTokenSuccess,
            idToken: token.idToken
        });
    };
}


export function SetAccessToken(token: any){
    return (dispatch: any) => {
        
        dispatch({
            type: AuthenticationActions.AcquiredAccessTokenSuccess,
            accessToken: token.accessToken
        });
    };
}