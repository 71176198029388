export const config ={
    REACT_APP_NAME: window?.appConfig?.REACT_APP_NAME,
    REACT_APP_ENV: window?.appConfig?.REACT_APP_ENV,
    REACT_APP_BASE_API_URL: window?.appConfig?.REACT_APP_BASE_API_URL,

    REACT_APP_OAUTH_AUTHORITY: window?.appConfig?.REACT_APP_OAUTH_AUTHORITY,
    REACT_APP_OAUTH_CLIENT_ID: window?.appConfig?.REACT_APP_OAUTH_CLIENT_ID,
    REACT_APP_OAUTH_SCOPE_BACKEND_API: window?.appConfig?.REACT_APP_OAUTH_SCOPE_BACKEND_API,
    REACT_APP_OAUTH_SCOPE_GRAPH_API: window?.appConfig?.REACT_APP_OAUTH_SCOPE_GRAPH_API,
    REACT_APP_DATADOG_DASHBOARD_URL: window?.appConfig?.REACT_APP_DATADOG_DASHBOARD_URL,
}