export class ColumnOption{
    headerText: string;
    itemProperty: string;
    orderField: string;
    formatHandler: any;

    constructor(headerText: string, itemProperty: string, orderField: string = '', formatHandler: any = null){
        this.headerText = headerText;
        this.itemProperty = itemProperty;
        this.orderField = orderField;
        this.formatHandler = formatHandler;
    }
}