import { red } from "@material-ui/core/colors";
import { Box, Card, CardContent, CssBaseline, IconButton, Typography } from "@mui/material";
import { Link } from "@reach/router";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";

type Props = {
    link: string,
    text: string,
    children: any,
}
function SideBarItem({ link, text, children }: Props) {

    var path = window.location.pathname;
    var color = "lightgray";
    if (path === link)
        color = "white";


    return (
        <>
            <Box>
                <Link to={link} style={{
                    color: color,
                    textDecoration: "none"
                }}>
                    <Box
                        display="flex"
                        flexDirection={"column"}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            marginTop: '30px',
                            marginRight: '30px',
                            marginLeft: '30px',
                            backgroundColor: "transparent",
                        }}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center">
                            {children}
                        </Box>
                        <Typography>
                            {text}
                        </Typography>
                    </Box>
                </Link>
            </Box>
        </>
    );
}


const mapStateToProps = (state: any) => {
    return state;
};

SideBarItem.defaultProps = {
    link: null,
    text: 'test'
};

export default connect(mapStateToProps)(SideBarItem);