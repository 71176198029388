import React, { useContext, useEffect, useState } from "react";
import {
  AuthenticationType,
  AzureMap,
  AzureMapsContext,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from "react-azure-maps";
import { Button } from "@material-ui/core";
import { data, layer, source } from "azure-maps-control";

const dataSourceRef = new source.DataSource();
const layerRef = new layer.SymbolLayer(dataSourceRef);

interface MapControllerProps {
  latitude: number;
  longitude: number;
}

export function MapController(props: MapControllerProps) {
  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: "bSy9N4SoNkHoLZb7Fo8p9ui3aCBq95J-znDnYYQiyhc",
    },
    zoom: 11,
    showLogo: false,
    showBuildingModels: true,
    showFeedbackLink: false,
  };

  // Here you use mapRef from context
  const { mapRef, isMapReady } =
    useContext<IAzureMapsContextProps>(AzureMapsContext);
  const [showTileBoundaries, setShowTileBoundaries] = useState(true);

  useEffect(() => {
    if (mapRef && !isNaN(+props.latitude) && !isNaN(+props.longitude)) {
      const lat: number = +props.latitude;
      const lon: number = +props.longitude;
      // Simple Camera options modification
      mapRef.setCamera({ center: [lon, lat] });
      const newPoint = new data.Position(lon, lat);
      dataSourceRef.clear();
      dataSourceRef.add(new data.Feature(new data.Point(newPoint)));
    }
  }, [, props.latitude, props.longitude, isMapReady]);

  useEffect(() => {
    if (mapRef) {
      // Simple Style modification
      mapRef.setStyle({ showTileBoundaries: !showTileBoundaries });
    }
  }, [showTileBoundaries]);

  useEffect(() => {
    if (isMapReady && mapRef) {
      // Need to add source and layer to map on init and ready
      mapRef.sources.add(dataSourceRef);
      mapRef.layers.add(layerRef);
    }
  }, [isMapReady]);

  // Util function to add pin
  const addRandomMarker = () => {
    const randomLongitude = Math.floor(Math.random() * (180 - -180) + -180);
    const randomLatitude = Math.floor(Math.random() * (-90 - 90) + 90);
    const newPoint = new data.Position(randomLongitude, randomLatitude);

    dataSourceRef.add(new data.Feature(new data.Point(newPoint)));
  };

  return (
    <>
      <div style={{ height: "300px" }}>
        <AzureMap options={option} />
      </div>
    </>
  );
}

// Util Function to generate random position
const getRandomPosition = () => {
  const randomLongitude = Math.floor(Math.random() * (180 - -180) + -180);
  const randomLatitude = Math.floor(Math.random() * (-90 - 90) + 90);
  return [randomLatitude, randomLongitude];
};

// Some styles
const styles = {
  buttonContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "10px",
    gridAutoColumns: "max-content",
    padding: "10px 0",
    alignItems: "center",
  },
  button: {
    height: 35,
    width: 80,
    backgroundColor: "#68aba3",
  },
};

export default MapController;
