import * as Actions from "../types/masterDataTypes";


const initialState = {
  loading: false,
  cacheReady: false,
  entityType: null,
  errorMessage: null,
  roles: [],
  sjs: [],
  ues: [],
  ups: []
};


export default function masterDataReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.BEGIN_ACTION_MASTERDATA:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case Actions.END_ACTION_MASTERDATA:
      switch (action.entityType) {
        case 'All':
          return {
            ...state,
            roles: action.roles,
            sjs: action.sjs,
            ues: action.ues,
            ups: action.ups,
            cacheReady: true,
            errorMessage: action.errorMessage,
            loading: false
          }
          break;
        case 'Roles':
          return {
            ...state,
            roles: action.roles,
            errorMessage: action.errorMessage,
            loading: false
          }
          break;
        case 'Sjs':
          return {
            ...state,
            sjs: action.sjs,
            errorMessage: action.errorMessage,
            loading: false
          }
          break;
        case 'Ues':
          return {
            ...state,
            ues: action.ues,
            errorMessage: action.errorMessage,
            loading: false
          }
          break;
        case 'Ups':
          return {
            ...state,
            ups: state.ups,
            errorMessage: action.errorMessage,
            loading: false
          }
          break;
        default:
          return {
            ...state,
            errorMessage: action.errorMessage,
            loading: false
          }

      }
    default:
      return state;
  }
};