import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    content: string;
    handleClose: any;
    handleConfirm: any;
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
    return(
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.content}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Annuler</Button>
                <Button onClick={props.handleConfirm}>Confirmer</Button>
            </DialogActions>
        </Dialog>
    )
}