import { Add, Delete } from "@mui/icons-material";
import { Button, IconButton, MenuItem, Select } from "@mui/material";
import TableInfinite from "components/common/tableInfinite";
import { TableLight } from "components/common/tableLight";
import TableResult from "components/common/tableResult";
import { ColumnOption } from "model/ColumnOption";
import { Site } from "model/Site";
import { useEffect, useState } from "react";
import {
  GetSiteWastes,
  GetUrlWaste,
  PostSiteWastes,
} from "services/siteApiCaller";
import "style/site/siteDetailWaste.scss";

interface SiteDetail_WasteProps {
  item: any;
}

export function SiteDetail_Waste(props: SiteDetail_WasteProps) {
  const columOptionsLight = [
    new ColumnOption("Code déchet", "siteWasteId", "siteWasteId"),
    new ColumnOption("Libellé déchet", "siteWasteName", "siteWasteName"),
  ];
  const [wastes, setWastes] = useState<any[]>([]);
  const [wasteReferential, setWasteReferential] = useState<any[]>([]);
  const [selectedWasteId, setSelectedWasteId] = useState<string | null>(null);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    const wasteRef = JSON.parse(localStorage.getItem("wastes") || "[]");
    setWasteReferential(wasteRef);
  }, []);

  useEffect(() => {
    if (props?.item?.identifier) {
      GetSiteWastes(props.item.identifier).then((res: any) => {
        setWastes(res);
      });
    }
  }, [props.item]);

  function handleAdd() {
    var selectedWaste = wasteReferential.find(
      (waste: any) => waste.id === selectedWasteId
    );
    setWastes([
      ...wastes,
      {
        siteId: props.item.identifier,
        siteWasteId: selectedWaste.id,
        siteWasteName: selectedWaste.label,
      },
    ]);
    setHasChanged(true);
  }

  function handleRemove(wasteId: string) {
    let tempWastes = wastes || [];
    tempWastes = wastes.filter((waste: any) => waste.siteWasteId !== wasteId);
    setWastes(tempWastes);
    setHasChanged(true);
  }

  function handleSave() {
    PostSiteWastes(props.item.identifier, wastes).then(() => {
      setHasChanged(false);
    });
  }

  function showHeader() {
    return (
      <div className="row">
        <div className="actionCell"></div>
        <div className="idCell">Code</div>
        <div className="nameCell">Libellé déchet</div>
      </div>
    );
  }

  function showItems() {
    return wastes.map((waste: any) => {
      return (
      <div className="row">
        <div className="actionCell">
          <IconButton onClick={() => handleRemove(waste.siteWasteId)}>
            <Delete />
          </IconButton>
        </div>
        <div className="idCell">{waste.siteWasteId}</div>
        <div className="nameCell">{waste.siteWasteName}</div>
      </div>
      );
    });
  }

  function showItem(waste: any) {
    return (
      <div className="row">
        <div className="actionCell">
          <IconButton onClick={() => handleRemove(waste.siteWasteId)}>
            <Delete />
          </IconButton>
        </div>
        <div className="idCell">{waste.siteWasteId}</div>
        <div className="nameCell">{waste.siteWasteName}</div>
      </div>
    );
  }

  function getListItems() {
    return wasteReferential.map((waste: any) => {
      return (
        <MenuItem value={waste.id}>{waste.id + " " + waste.label}</MenuItem>
      );
    });
  }

  return (
    <>
      <div className="siteDetailWasteContainer">
        <div className="paperDiv">
          <div className="titleForm">Gestion des déchets</div>

          <div className="siteArrayContainer" style={{ height: "400px" }}>
            {showHeader()}
            <>
            {showItems()}
            </>
          </div>

          <div className="divSelectWaste">
            <Select
              className="selectWaste"
              value={selectedWasteId}
              onChange={(e) => {
                setSelectedWasteId(e.target.value as string);
              }}
            >
              {getListItems()}
            </Select>
            <IconButton onClick={handleAdd}>
              <Add />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="siteDetailAction">
        <Button
          variant="contained"
          className={
            "siteButton" +
            (hasChanged ? " siteButtonVisible" : " siteButtonInvisible")
          }
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}
