
import React, { useState, useRef, useEffect, ReactElement } from "react";
import { connect } from "react-redux";

function PrivateRoute({ component: Component, isAuthenticated, ...rest }: any) {
  useEffect(() => {

  },
    []);

  if (isAuthenticated)
    return (
      <Component {...rest} />
    );
  else
    return (
      <div>Not Authenticated</div>
    );
}

const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.authentReducer.isAuthenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);

