
import { Router } from "@reach/router";
import React, { useState, useRef, useEffect, ReactElement } from "react";
import { connect } from "react-redux";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration } from "msal";
import * as AuthActions from '../../store/actions/authActions';
import {config} from "config";


const configOauth: Configuration = {
    auth: {
      authority:  config.REACT_APP_OAUTH_AUTHORITY,
      clientId: config.REACT_APP_OAUTH_CLIENT_ID,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation:"localStorage",
      storeAuthStateInCookie: true,
    },
  };
  
  // Authentication Parameters
  const authenticationParameters = {
    scopes: [config.REACT_APP_OAUTH_SCOPE_BACKEND_API],
  };
  const authenticationParametersGraph = {
    scopes: [config.REACT_APP_OAUTH_SCOPE_GRAPH_API],
  };
  // Options
  const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin,
  };

  const authProvider = new MsalAuthProvider(
    configOauth,
    authenticationParameters,
    options
  );

function AuthProvider({ children, name, setIdToken, setAccessToken }: any) {
    const [firstTime, setFirstTime] = useState(true);

    function AuthenticationProcess(){
        authProvider.getIdToken(authenticationParameters)
        .then((idToken) =>
        {
            setIdToken(idToken);
            GetAccessToken();
        })
        .catch((error) => 
        {
            authProvider.loginRedirect(authenticationParameters);
        });
    }

    function GetAccessToken(){
        authProvider.getAccessToken(authenticationParameters)
        .then((accessToken) =>
        {
            setAccessToken(accessToken);
            setFirstTime(false);
        })
        .catch((error) =>
        {

        });
    }

    useEffect(() => {
        if (firstTime){
            AuthenticationProcess();
        }
        else{

        }
    });

    return (
        <Router>
            {children}
        </Router>
    );
}


const mapStateToProps = (state: any) => {
    return {
        name: state.authentReducer.name,
    };
};

const mapDispatchToProps = {
    setIdToken: AuthActions.SetIdToken,
    setAccessToken: AuthActions.SetAccessToken
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

