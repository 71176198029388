import { CheckBox } from "@mui/icons-material";
import { FormControlLabel, Input } from "@mui/material";
import { WorkflowSearchCriteria } from "model/WorkflowSearchCriteria";
import "style/workflow.scss";

interface WorkflowToolbarProps {
    criterias: WorkflowSearchCriteria;
    onChange: any;
}

export function WorkflowToolbar(props:WorkflowToolbarProps) {

    return (
        <div className="workflowTollbarContainer">
        <FormControlLabel control={<input type="checkbox" checked={props.criterias.isActive} onChange={(e:any) => props.onChange("isActive", e.currentTarget.checked)} />} label="Inclure Workflows terminés" />
        </div>
    );
}