import { Box, Tabs, Tab } from "@mui/material";
import { TabPanel } from "components/common/tabPanel";
import { ManagedCorporationList } from "components/managedCorporation/managedCorporationList";
import MainLayout from "layouts/mainLayout";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import "style/corporation.scss";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientWorkspace({}) {
  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <MainLayout>
        <ManagedCorporationList />
      </MainLayout>
    </>
  );
}
