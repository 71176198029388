import { Check, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import TableInfinite from "components/common/tableInfinite";
import TableResult from "components/common/tableResult";
import { ColumnOption } from "model/ColumnOption";
import { MapPermissionRole } from "services/mapper";
import useWindowDimensions from "services/windowDimensions";

interface CustomerListProps {
  items: any;
  pagingParameter: any;
  pageChangingHandler: any;
  onRowSelected?: any;
  selectedItem?: any;
  setSelectedItem?: any;
}

export function CustomerList(props: CustomerListProps) {
  const columOptions = [
    new ColumnOption("", "isActive", "p.is_active", (item: any) => {
      if (item.isActive)
        return (
          <Tooltip title="Actif" placement="left">
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                item.isActive = false;
                //postPermission(item);
              }}
            >
              <Check sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        );
      else
        return (
          <Tooltip title="Inactif" placement="left">
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                item.isActive = true;
                //postPermission(item);
              }}
            >
              <RemoveCircleOutline sx={{ fontSize: 16 }} />
            </IconButton>
          </Tooltip>
        );
    }),
    new ColumnOption("", "isInternal", "cl.is_internal", (item: any) => {
      if (item.isInternal)
        return <img src="images/colas_logo.png" width={50} alt="image" />;
      else return <></>;
    }),
    new ColumnOption("Id", "id", "cl.id"),
    new ColumnOption("Statut", "accessStatus", "sj.access_status"),
    new ColumnOption("Ue", "ueCode", "cl.exploitation_unit_id"),
    new ColumnOption("Name", "name", "cl.name"),
    new ColumnOption("Siret", "siret", "cl.siret"),
  ];

  var dim = useWindowDimensions();
  var tableHeight = 600;
  if (dim.height > 900) tableHeight = 800;

  return (
    <div
      style={{
        height: tableHeight + "px",
        width: "100%",
      }}
    >
      <TableResult
        headers={columOptions}
        items={props.items.items}
        count={props.items.count}
        pagingParam={props.pagingParameter}
        onPageChange={props.pageChangingHandler}
        onRowSelected={props.onRowSelected}
        idProperty="id"
        selectedItem={props.selectedItem}
        setSelectedItem={props.setSelectedItem}
      />
    </div>
  );
}
