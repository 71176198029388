import { Autocomplete, Checkbox, FormControlLabel, Input, TextField } from "@mui/material";
import "style/customerStyle.scss";

interface CustomerSearchProps {
  criterias: any;
  changeCriterias: any;
}

const customerStatuses = [
  { label: "Pas d'accès", id: "NO_ACCESS" },
  { label: "Accès", id: "ACCESS" },
  { label: "Invitation en cours", id: "INVITATION_PENDING" },
  { label: "Invitation expirée", id: "INVITATION_EXPIRED" },
];

export function CustomerSearchCriteria(props: CustomerSearchProps) {


  function getStatusOptions() {
    return customerStatuses;
  }
  function getSelectedStatusOption() {
    var currentSj = customerStatuses.find((item: any) => item.id == props.criterias.status);
    if (currentSj != null && currentSj != undefined)
      return {
        label: currentSj.label,
        id: currentSj.id,
      };
    else return null;
  }

  return (
    <>
      <TextField
        label="Name / Siret"
        variant='standard'
        value={props.criterias.name || ""}
        onChange={(e) => props.changeCriterias("name", e.currentTarget.value)}
        className="customerTextFieldStyle"
      />
      <Autocomplete
        id="ac-status"
        onChange={(e, value: any) => {
          props.changeCriterias("status", value?.id);
        }}
        options={getStatusOptions()}
        defaultValue={getSelectedStatusOption()}
        value={getSelectedStatusOption()}
        className="customerTextFieldStyle"
        renderInput={(params) => (
          <TextField
            variant='standard'
            className="textFieldStyle"
            {...params}
            label="Statut"
          />
        )}
      />
      <FormControlLabel
        style={{marginTop:"10px"}}
        control={
          <Checkbox
            checked={props.criterias.isActive !== null && props.criterias.isActive !== undefined && props.criterias.isActive as boolean}
            indeterminate={props.criterias.isActive === null || props.criterias.isActive === undefined}
            onChange={(e: any) =>
              {
                let newValue = true as Nullable<boolean>;
                if(props.criterias.isActive === null) newValue = true;
                else if(props.criterias.isActive) newValue = false;
                else if(!props.criterias.isActive) newValue = null;
                props.changeCriterias("isActive", newValue)
              }
            }
          />
        }
        label="Est actif"
      />
      <FormControlLabel
        style={{marginTop:"10px"}}
        control={
          <Checkbox
          checked={props.criterias.isInternal !== null && props.criterias.isInternal !== undefined && props.criterias.isInternal as boolean}
          indeterminate={props.criterias.isInternal === null || props.criterias.isInternal === undefined}
            onChange={(e: any) =>
              {
                let newValue = true as Nullable<boolean>;
                if(props.criterias.isInternal === null) newValue = true;
                else if(props.criterias.isInternal) newValue = false;
                else if(!props.criterias.isInternal) newValue = null;
                props.changeCriterias("isInternal", newValue)
              }
            }
          />
        }
        label="Est Interne"
      />
    </>
  );
}
