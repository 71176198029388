import * as Actions from "../types/yellowstoneTypes"; 


export enum TypeEntity{
  Site = "Site",
  Customer = "Customer",
  Activity = "Activity",
  Permission = "Permission",
  SiteActivity = "SiteActivity",
  UserMetadata = "UserMetadata",
}

export enum TypeAction{
  GetList = "GetList",
  GetOne = "GetOne",
  Save = "Save"
}

const initialState = {
  actionPending: false,
  typeEntity: null,
  typeAction: null,
  errorMessage: null,
  entities: [[]],
};


export default function yellowstoneReducer(state = initialState, action: any) {
  switch (action.type) {
    case Actions.BEGIN_ACTION:
      return{
        ...state,
        typeEntity: action.typeEntity,
        typeAction: action.typeAction,
        errorMessage: null,
        actionPending: true
      };
      case Actions.END_ACTION:
        var array = state.entities;
        array[action.typeEntity] = action.entities;
        return{
          ...state,
          entities : action.entities, 
          errorMessage: action.errorMessage,
          actionPending: false
        };
        case Actions.END_ACTION_WITHERROR:
          return{
            ...state, 
            errorMessage: action.errorMessage,
            actionPending: false
          };
    default:
      return state;
  }
};