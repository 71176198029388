import * as ApiActions from "./apiCaller";
import { config } from "config";

export async function GetSites(
  pagingParameter: any,
  searchCriterias: any
) {
  var isAsc = "true";
  if (!pagingParameter.isAsc) isAsc = "false";
  var baseUrl =
    config.REACT_APP_BASE_API_URL +
    "Sites?StartPage=" +
    pagingParameter.currentPage +
    "&Count=" +
    pagingParameter.pageSize +
    "&OrderField=" +
    pagingParameter.currentSortId +
    "&IsAsc=" +
    pagingParameter.isAsc;
  if (searchCriterias) {
    if (searchCriterias.freeSearch)
      baseUrl += "&FreeSearch=" + searchCriterias.freeSearch;
    if (searchCriterias.name)
      baseUrl += "&Name=" + searchCriterias.name;
    if (searchCriterias.sj)
      baseUrl += "&Sj=" + searchCriterias.sj;
    if (searchCriterias.addressLine1)
      baseUrl += "&AddressLine1=" + searchCriterias.addressLine1;
    if (searchCriterias.city)
      baseUrl += "&City=" + searchCriterias.city;
  }

  const result = await ApiActions.getAPI(baseUrl);

  return result.data;
}

export function PostSite(site:any){
  var baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites";
  return ApiActions.postAPI(baseUrl,site);
}

export function GetUrlWaste(pagingParameter:any, criterias:any ){
  const baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites/"+criterias.id+"/Wastes";
  return baseUrl;
}

export async function GetSiteWastes(id:number){
  var baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites/"+id+"/Wastes";
  const result = await ApiActions.getAPI(baseUrl);

  return result.data;
}

export async function PostSiteWastes(id:number, wastes:any){
  var baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites/"+id+"/Wastes";
  return ApiActions.postAPI(baseUrl,wastes);
}

export async function GetSiteActivities(id:number){
  var baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites/"+id+"/Activities";
  const result = await ApiActions.getAPI(baseUrl);

  return result.data;
}

export async function PostSiteActivities(id:number, activities:any){
  var baseUrl =
  config.REACT_APP_BASE_API_URL +
  "Sites/"+id+"/Activities";
  return ApiActions.postAPI(baseUrl,activities);
}
