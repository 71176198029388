import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GetSites, PostSite } from "services/siteApiCaller";
import { SiteList } from "./siteList";
import { SiteToolbar } from "./siteToolbar";
import { Close } from "@mui/icons-material";
import WaitingComponent from "components/common/waitingComponent";
import "style/siteStyle.scss";
import useWindowDimensions from "services/windowDimensions";
import { Site } from "model/Site";
import { SiteDetail } from "./siteDetail";

interface SiteListDialogParams {
  sjCode: string;
  open: boolean;
  handleClose: () => void;
}

export default function SiteListDialog({
  sjCode,
  open,
  handleClose,
}: SiteListDialogParams) {
  var dim = useWindowDimensions();
  var pageSize = 10;
  if (dim.height > 900) pageSize = 17;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [criterias, setCriterias] = useState({ sj: sjCode, freeSearch: "" });
  const [pagingParameters, setPagingParameters] = useState({
    currentPage: 0,
    pageSize: pageSize,
    currentSortId: "s.id",
    isAsc: false,
  });
  const [datas, setDatas] = useState([]);
  const [layoutAndDetailProperties, setLayoutAndDetailProperties] =
    useState<any>({
      listPartWidth: 100,
      detailPartWidth: 0,
      selectedItem: null as Site | null,
    });

  useEffect(() => {
    handleSearch();
  }, [pagingParameters]);

  function handleSearch() {
    setIsLoading(true);
    GetSites(pagingParameters,criterias).then(
      (response: any) => {
        setDatas(response);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function handleImport() {
    console.log("handleImport");
  }

  function handleExport() {
    console.log("handleExport");
  }

  function changeCriterias(key: string, value: any) {
    setCriterias({ ...criterias, [key]: value });
  }

  function handlePageChange(param: any) {
    setPagingParameters(param);
  }
  function adjustLayout(item: any) {
    if (item) {
      setLayoutAndDetailProperties({
        listPartWidth: 30,
        detailPartWidth: 70,
        selectedItem: item,
      });
    } else {
      setLayoutAndDetailProperties({
        listPartWidth: 100,
        detailPartWidth: 0,
        selectedItem: item,
      });
    }
  }

  function handleDetailChange(value: string, field: string) {
    const properties = field.split(".");
    var objectValue = null;
    if (properties.length > 1) {
      var objectValue = layoutAndDetailProperties.selectedItem[properties[0]];
      if(objectValue){
        objectValue[properties[1]] = value;
      }
      else{
        objectValue = {} as any;
        objectValue[properties[1]] = value;
      }
    }
    setLayoutAndDetailProperties({
      ...layoutAndDetailProperties,
      selectedItem: {
        ...layoutAndDetailProperties.selectedItem,
        [properties[0]]: properties.length > 1 ? objectValue : value,
      },
    });
  }

  function handleDetailSave() {
    PostSite(layoutAndDetailProperties.selectedItem)
      .then((response) => {
        handleSearch();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getContent() {
    if (isLoading) {
      return <WaitingComponent message={"Sites en cours de chargement"} />;
    } else {
      return (
        <>
          <div
            className="siteListPart"
            style={{ width: layoutAndDetailProperties.listPartWidth + "%" }}
          >
            <SiteToolbar
              handleSearch={handleSearch}
              handleImport={handleImport}
              handleExport={handleExport}
              handleCreate={() => setLayoutAndDetailProperties({
                listPartWidth: 30,
                detailPartWidth: 70,
                selectedItem: {},
              })}
              changeCriterias={changeCriterias}
              criterias={criterias}
            />
            <SiteList
              items={datas}
              pagingParameter={pagingParameters}
              pageChangingHandler={(event: any, param: any) =>
                handlePageChange(param)
              }
              onRowSelected={adjustLayout}
              isFull={layoutAndDetailProperties.selectedItem ? false : true}
              selectedItem={layoutAndDetailProperties.selectedItem}
              setSelectedItem={(item: Site) => {
                setLayoutAndDetailProperties({
                  ...layoutAndDetailProperties,
                  selectedItem: item,
                });
              }}
            />
          </div>
          <div
            className="siteDetailPart"
            style={{
              width: layoutAndDetailProperties.detailPartWidth + "%",
              visibility:
                layoutAndDetailProperties.detailPartWidth == 0
                  ? "hidden"
                  : "visible",
            }}
          >
            <SiteDetail item={layoutAndDetailProperties.selectedItem} handleChange={handleDetailChange} handleSave={handleDetailSave} />
          </div>
        </>
      );
    }
  }

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} className="dialogSite">
        <Toolbar className="toolbarSite">
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Liste des sites de la société {sjCode}
          </Typography>
          <IconButton>
            <Close sx={{ color: "white" }} onClick={() => handleClose()} />
          </IconButton>
        </Toolbar>
        <div className="siteDialogContent">{getContent()}</div>
      </Dialog>
    </>
  );
}
