import { Check, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import TableResult from "components/common/tableResult";
import { ColumnOption } from "model/ColumnOption";
import { Workflow } from "model/Workflow";
import { WorkflowItem } from "./workflowItem";
import { NoContent } from "components/common/commonComponent";

interface WorkflowListProps {
  workflows: Workflow[];
}

export function WorkflowList(props: WorkflowListProps) {


  function buildList(){
    return props.workflows.map((workflow) => {
        return(
        <WorkflowItem workflow={workflow} />
        );
    });
  }



  return (
    <>
    {props.workflows.length > 0 ? buildList() : NoContent()}
    </>
  );
}
