import {
  Check,
  Edit,
  ExpandMore,
  LocationOn,
  RemoveCircleOutline,
} from "@mui/icons-material";
import TableResult from "components/common/tableResult";
import { ColumnOption } from "model/ColumnOption";
import { Site } from "model/Site";
import { useState } from "react";
import useWindowDimensions from "services/windowDimensions";

interface SiteListProps {
  items: any;
  pagingParameter: any;
  pageChangingHandler: any;
  onRowSelected?: any;
  isFull?: boolean;
  selectedItem?: Site;
  setSelectedItem?: (arg0:Site)=>void;
}

export function SiteList(props: SiteListProps) {
  const columOptionsLight = [
    new ColumnOption("", "", "", (item: any) => {
      if (item.isActive)
        return (
          <>
            <Check
              sx={{ fontSize: 16, marginRight: "5px", cursor: "pointer" }}
            />
          </>
        );
      else
        return (
          <>
            <RemoveCircleOutline
              sx={{ fontSize: 16, marginRight: "5px", cursor: "pointer" }}
            />
          </>
        );
    }),
    new ColumnOption("Id", "identifier", "s.id"),
    new ColumnOption("Name", "name", "name"),
  ];
  const columOptionsLFull = [
    new ColumnOption("", "", "", (item: any) => {
      if (item.isActive)
        return (
          <>
            <Check
              sx={{ fontSize: 16, marginRight: "5px", cursor: "pointer" }}
            />
          </>
        );
      else
        return (
          <>
            <RemoveCircleOutline
              sx={{ fontSize: 16, marginRight: "5px", cursor: "pointer" }}
            />
          </>
        );
    }),
    new ColumnOption("Id", "identifier", "s.id"),
    new ColumnOption("Name", "name", "name"),
    new ColumnOption("Adresse Ligne 1", "address_Line1", "address_Line1"),
    new ColumnOption("Adresse Ligne 2", "address_Line2", "address_Line2"),
    new ColumnOption("Code postal", "address_ZipCode", "address_ZipCode"),
    new ColumnOption("Commune", "address_City", "address_City"),
  ];
  const [selectedItem, setSelectedItem] = useState({});

  var dim = useWindowDimensions();
  var tableHeight = 600;
  if (dim.height > 900) tableHeight = 700;

  function getColumnOptions() {
    if (props.isFull) return columOptionsLFull;
    else return columOptionsLight;
  }

  return (
    <div
      style={{
        height: tableHeight + "px",
        width: "100%",
      }}
    >
      <TableResult
        headers={getColumnOptions()}
        items={props.items.items}
        count={props.items.count}
        pagingParam={props.pagingParameter}
        onPageChange={props.pageChangingHandler}
        onRowSelected={props.onRowSelected}
        idProperty="identifier"
        selectedItem={props.selectedItem}
        setSelectedItem={props.setSelectedItem}
      />
    </div>
  );
}
