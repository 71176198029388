import { MiscellaneousServices } from "@mui/icons-material";
import { Badge, Icon, IconButton, Link } from "@mui/material";
import { getWorkflowCount } from "services/workflowApiCaller";
import { useEffect, useState } from "react";
import { WorkflowDrawer } from "./workflowDrawer";

export function WorkflowNavBar() {
  const [workflowCount, setWorkflowCount] = useState<number>(0);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [isWorkflowDrawerOpen, setIsWorkflowDrawerOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
      retrieveWorkflowCount();
      scheduleWorkflowCount();
    }
  }, []);

  function scheduleWorkflowCount() {
    setTimeout(() => {
      retrieveWorkflowCount();
      scheduleWorkflowCount();
    }, 5000);
  }

  function retrieveWorkflowCount() {
    getWorkflowCount().then((data) => {
      setWorkflowCount(data);
    });
  }

  function showDrawerWorkflow() {
    setIsWorkflowDrawerOpen(true);
  }

  function hideDrawerWorkflow() {
    setIsWorkflowDrawerOpen(false);
  }

  function getWorkflowIcon() {
    return (
      <>
        <IconButton onClick={showDrawerWorkflow}>
          <Badge badgeContent={workflowCount} color="primary">
            <MiscellaneousServices />
          </Badge>
        </IconButton>
        <WorkflowDrawer open={isWorkflowDrawerOpen} onClose={hideDrawerWorkflow} />
      </>
    );
  }
  return <>{getWorkflowIcon()}</>;
}
