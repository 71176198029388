import { Add, FileDownload, FileUpload, Search } from "@mui/icons-material";
import { IconButton, Input, Tooltip } from "@mui/material";
import { FileUploadManager } from "components/common/fileUploadManager";
import { useRef, useState } from "react";
import "style/siteStyle.scss";

interface SiteToolbarCriteriaProps {
  handleSearch: any;
  handleImport: any;
  handleExport: any;
  handleCreate: any;
  changeCriterias: any;
  criterias: any;
}

export function SiteToolbar(props: SiteToolbarCriteriaProps) {
  const [isImportPopupOpen, setIsImportPopupOpen] = useState<boolean>(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState<boolean>(false);

  const anchorUploadRef = useRef(null);

  return (
    <div className="userSearchMainContainer">
      <form onSubmit={props.handleSearch}>
        <Input
          value={props.criterias.freeSearch}
          onChange={(e: any) => {
            props.changeCriterias("freeSearch", e.target.value);
          }}
        />
        <Tooltip title="Recherche libre" placement="top">
          <IconButton onClick={props.handleSearch}>
            <Search />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajouter" placement="top">
          <IconButton onClick={props.handleCreate}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter" placement="top">
          <IconButton onClick={() => props.handleExport}>
            <FileDownload />
          </IconButton>
        </Tooltip>
        <Tooltip title="Importer" placement="top">
          <IconButton
            onClick={() => setIsImportPopupOpen(true)}
            ref={anchorUploadRef}
          >
            <FileUpload />
          </IconButton>
        </Tooltip>
      </form>
      <>
        <FileUploadManager
        title="Chagement fichier excel"
          isopen={isImportPopupOpen}
          anchorRef={anchorUploadRef}
          handleCancel={() => setIsImportPopupOpen(false)}
        />
      </>
    </div>
  );
}
