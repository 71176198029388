import { Block, Check, DeviceUnknown, Error, Pending, Start } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import "style/common.scss";

export function ShowStatus(item: any) {
    switch (item.status) {
      case 0:
        return (
          <span className="item-status item-status-intialized">
            <Tooltip title="initialisé" placement="top">
              <Start />
            </Tooltip>
          </span>
        );
        break;
      case 1:
        return (
          <span className="item-status item-status-pending">
            <Tooltip title="en cours" placement="top">
              <Pending />
            </Tooltip>
          </span>
        );
        break;
      case 2:
        return (
          <span className="item-status item-status-deployed">
            <Tooltip title="déployée" placement="top">
              <Check />
            </Tooltip>
          </span>
        );
      case 3:
        return (
          <span className="item-status item-status-error">
            <Tooltip title="en erreur" placement="top">
              <Error />
            </Tooltip>
          </span>
        );
        break;
      default:
        return (
          <span className="item-status item-status-intialized">
            <Tooltip title="statut inconnu" placement="top">
              <DeviceUnknown />
            </Tooltip>
          </span>
        );
        break;
    }
  }

export function NoContent() {
  return (
  <Box
    alignItems="center"
    width="100%"
    height="100%"
    display="flex"
    flexDirection="column">
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Box alignContent="center">
        <Block sx={{ fontSize: '100px' }} />
      </Box>
      <Box alignContent="center">
        <Typography sx={{ fontSize: '20px' }}>Pas de contenu</Typography>
      </Box>
      
    </Box>
  </Box>
  );
}