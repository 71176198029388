export class VCustomer{
    id?: Nullable<number>;
    sjCode?: Nullable<string>;
    name?: Nullable<string>;
    siret?: Nullable<string>;
    IsInternal?: Nullable<boolean>;
    isActive?: Nullable<boolean>;
    UeCode?: Nullable<string>;
    AccessStatus?: Nullable<string>;
    ReferentEmail?: Nullable<string>;
    ReferentFirstName?: Nullable<string>;
    ReferentLastName?: Nullable<string>;
}