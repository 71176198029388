import { Site } from "model/Site";
import { SiteDetailProps } from "./siteDetail";
import { Button, Paper } from "@mui/material";
import { SiteDetail_GeneralInformation } from "./siteDetail_GeneralInformation";
import { SiteDetail_Address } from "./siteDetail_Address";
import { SiteDetail_Waste } from "./siteDetail_Waste";
import { SiteDetail_Activity } from "./siteDetail_Activity";
import "style/siteStyle.scss";
import { SiteDetail_Structure } from "./siteDetail_Structure";
import { useState } from "react";

export function SiteDetail_ContainerGeneral(props: SiteDetailProps) {
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  function handleChange(value: string, field: string) {
    props.handleChange(value, field);
    setHasChanged(true);
  }

  return (
    <>
      <div className="siteDetailGeneralinformationContainer">
        <div className="paperDiv topLeft">
          <SiteDetail_GeneralInformation
            item={props.item}
            handleChange={handleChange}
          />
        </div>
        <div className="paperDiv topRight">
          <SiteDetail_Structure item={props.item} handleChange={handleChange} />
        </div>
        <div className="paperDiv bottom">
          <SiteDetail_Address item={props.item} handleChange={handleChange} />
        </div>
      </div>
      <div className="siteDetailAction">
        <Button variant="contained" className={"siteButton" + (hasChanged ? " siteButtonVisible" : " siteButtonInvisible")} onClick={props.handleSave}>
          Enregistrer
        </Button>
      </div>
    </>
  );
}
