export class VUser{
    id?: Nullable<number>;
    email?: Nullable<string>;
    sj?: Nullable<string>;
    customerMdmId?: Nullable<number>;
    roleId?: Nullable<number>;
    isActive?: Nullable<boolean>;
    firstName?: Nullable<string>;
    lastName?: Nullable<string>;
    userMetadataId?: Nullable<string>;
    phoneNumber?: Nullable<string>;
}