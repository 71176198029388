import "./App.css";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import Startup from "pages/startup";
import AuthProvider from "components/security/authProvider";
import PublicRoute from "components/security/publicRoute";
import PrivateRoute from "components/security/privateRoute";
import Dashboard from "pages/dashboard";
import ClientWorkspace from "pages/clientWorkspace";
import { Style } from "@mui/icons-material";

function App({ authentReducer }: any) {
  return (
    <StyledEngineProvider injectFirst={true}>
      <AuthProvider>
        <PublicRoute path="/" component={Startup} />
        <PrivateRoute path="/Home" component={ClientWorkspace} />
        <PrivateRoute path="/Dashboard" component={Dashboard} />
      </AuthProvider>
    </StyledEngineProvider>
  );
}

export default App;
