import { Drawer, makeStyles } from '@material-ui/core';
import { WorkflowList } from "./workflowList";
import { WorkflowToolbar } from "./workflowToolbar";
import { WorkflowSearchCriteria } from "model/WorkflowSearchCriteria";
import { getWorkflows } from "services/workflowApiCaller";
import { Workflow } from "model/Workflow";
import "style/workflow.scss";
import { useEffect, useState } from 'react';

interface WorkflowDrawerProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme?: any) => ({
    paper: {
      background: 'lightgrey',
      color: 'white'
    }
  }));

export function WorkflowDrawer(props: WorkflowDrawerProps) {
    const styles = useStyles();
    const [firstTime, setFirstTime] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState(
      {isActive: false} as WorkflowSearchCriteria
    );
    const [workflows, setWorkflows] = useState([] as Workflow[]);
    
  useEffect(() => {
    if(props.open){
      search();
    }
  }, [props.open, searchCriteria]);


  function search(){
    getWorkflows(searchCriteria)
        .then((response) => {
          setWorkflows(response);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
  }

  function criteriasChange(propertyName: string, value: any) {
    var newSearchCriteria = Object.assign({}, searchCriteria);
    if (propertyName === "isActive") newSearchCriteria.isActive = value;
    setSearchCriteria(newSearchCriteria);
  }

  return (
    <>
      <Drawer
        open={props.open}
        onClose={props.onClose}
        anchor="right"
        classes={{ paper: styles.paper }}
      >
        <div className="workflowDrawerContainer">
          <div className="workflowDrawerHeader">
            <WorkflowToolbar criterias={searchCriteria} onChange={criteriasChange} />
          </div>
          <div className="workflowDrawerBody">
            <WorkflowList workflows={workflows} />
          </div>
        </div>
      </Drawer>
    </>
  );
}
