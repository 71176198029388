import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { PostManagedCorporation } from "services/managedCorporationApiCaller";
import { SketchPicker } from "react-color";
import { ManagedCorporation } from "model/ManagedCorporation";

import "style/corporationCreation.scss";
import { Close, Colorize, DoneOutline } from "@mui/icons-material";
import { ManagedCorporationIntegrationLauncher } from "./managedCorporationIntegrationLauncher";

interface ManagedCorporationCreationProps {
  item: ManagedCorporation;
  open: boolean;
  handleCreation: (item: ManagedCorporation, launchWorkflow: boolean, parameters: any) => void;
  handleClose: () => void;
}

export function ManagedCorporationCreation({
  item,
  open,
  handleCreation,
  handleClose,
}: ManagedCorporationCreationProps) {
  var [corporation, setCorporation] = useState<ManagedCorporation>(item);
  var [popupColorOpen, setPopupColorOpen] = useState(false);
  var [popupIntegrationOpen, setPopupIntegrationOpen] = useState(false);
  var [imageSelected, setImageSelected] = useState("inputRefImageB2c");
  var [launchWorkflow, setLaunchWorkflow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRefImageB2c = useRef<HTMLImageElement>(null);
  const inputRefImageBrand = useRef<HTMLImageElement>(null);
  const inputRefImageFavIcon = useRef<HTMLImageElement>(null);
  const inputRefUpload = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCorporation(item);
  }, [item]);

  

  function updateCorporation(value: any, property: string) {
    var currentCorporation: any = {};
    currentCorporation[property] = value;
    setCorporation({ ...corporation, ...currentCorporation });
  }

  function showColorPicker(event: any) {
    setPopupColorOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function showIntegrationPopup(event: any) {
    setPopupIntegrationOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function hiddeColorPicker() {
    setPopupColorOpen(false);
    setAnchorEl(null);
  }

  function hiddeIntegrationPopup() {
    setPopupIntegrationOpen(false);
    setAnchorEl(null);
  }
  

  function handleImageSelected(evt: any) {
    var file: any = null;
    if (inputRefUpload.current) file = inputRefUpload.current.files?.item(0);
    if (file) {
      var currentCorporation: any = {};
      var inputRef = inputRefImageB2c;
      var imageproperty: string = "b2cImage";
      if (imageSelected == "inputRefImageBrand") {
        inputRef = inputRefImageBrand;
        imageproperty = "logoImage";
      }
      if (imageSelected == "inputRefImageFavIcon") {
        inputRef = inputRefImageFavIcon;
        imageproperty = "favIconImage";
      }
      if (inputRef.current)
        inputRef.current.src = URL.createObjectURL(file as Blob);
      currentCorporation[imageproperty] = file;
      setCorporation({ ...corporation, ...currentCorporation });
    }
  }

  function handleValidateCorporation(parameters: any) {
    if (handleCreation) handleCreation(corporation, false, parameters);
  }

  function handleValidateCorporationWithWorkflowLaunch(parameters: any) {
    if (handleCreation) handleCreation(corporation, true, parameters);
  }

  function GetTitle() {
    if (corporation.corporationName)
      return corporation.corporationName + " (" + corporation.sjCode + ")";
    else return "Nouvelle société";
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle sx={{ backgroundColor: "black", color: "white" }}>
          <div className="corporationtoolbar">
            {GetTitle()}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <Close sx={{ color: "white" }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "var(--page-bg-color)" }}>
          <div className="mainBox">
            <div className="partLeft">
              <Paper className="part partPaper">
                <Typography variant="h6" className="titre">
                  Informations générales
                </Typography>
                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <InputLabel
                    id="corporationName-label"
                    htmlFor="corporationName-text"
                  >
                    Nom
                  </InputLabel>
                  <Input
                    type="text"
                    id="corporationName-text"
                    name="corporationName"
                    placeholder="label technique de la société (utilisé pour les urls, ...)"
                    value={corporation.corporationName}
                    onChange={(e) =>
                      updateCorporation(e.target.value, "corporationName")
                    }
                    required
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <InputLabel id="displayName-label" htmlFor="displayName-text">
                    Nom d'affichage
                  </InputLabel>
                  <Input
                    type="text"
                    id="displayName-text"
                    name="corporationDisplayName"
                    value={corporation.corporationDisplayName}
                    placeholder="nom court de la société (utilisé pour l'affichage)"
                    onChange={(e) =>
                      updateCorporation(
                        e.target.value,
                        "corporationDisplayName"
                      )
                    }
                    required
                  />
                </FormControl>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="codeSj-label" htmlFor="codeSj-text">
                      Code SJ
                    </InputLabel>
                    <Input
                      type="text"
                      id="codeSj-text"
                      name="sjCode"
                      value={corporation.sjCode}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "sjCode")
                      }
                      required
                    />
                  </FormControl>
                  <div>&nbsp;</div>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel
                      id="synchronizationFilter-label"
                      htmlFor="synchronizationFilter-text"
                    >
                      Filtre de synchronisation
                    </InputLabel>
                    <Input
                      type="text"
                      id="synchronizationFilter-text"
                      name="synchronizationFilter"
                      value={corporation.synchronizationFilter}
                      onChange={(e) =>
                        updateCorporation(
                          e.target.value,
                          "synchronizationFilter"
                        )
                      }
                      required
                    />
                  </FormControl>
                </div>
              </Paper>
              <Paper className="part partPaper partPaperDottom">
                <Typography variant="h6" className="titre">
                  Données administratives
                </Typography>
                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <InputLabel id="fullname-label" htmlFor="fullname-text">
                    Nom Complet
                  </InputLabel>
                  <Input
                    type="text"
                    id="fullname-text"
                    name="fullname"
                    placeholder="nom long de la société utilisé pour les fichiers rgpd type cgu, ..."
                    value={corporation.corporationFullName}
                    onChange={(e) =>
                      updateCorporation(e.target.value, "corporationFullName")
                    }
                    required
                  />
                </FormControl>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel
                      id="tvaintracom-label"
                      htmlFor="tvaintracom-text"
                    >
                      Tva Intracom
                    </InputLabel>
                    <Input
                      type="text"
                      id="tvaintracom-text"
                      name="tvaintracom"
                      value={corporation.corporationIntracom}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "corporationIntracom")
                      }
                      required
                    />
                  </FormControl>
                  <div>&nbsp;</div>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="rcs-label" htmlFor="rcs-text">
                      RCS
                    </InputLabel>
                    <Input
                      type="text"
                      id="rcs-text"
                      name="rcs"
                      value={corporation.corporationRcs}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "corporationRcs")
                      }
                      required
                    />
                  </FormControl>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="capital-label" htmlFor="capital-text">
                      Capital
                    </InputLabel>
                    <Input
                      type="text"
                      id="capital-text"
                      name="capital"
                      value={corporation.corporationCapital}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "corporationCapital")
                      }
                      required
                    />
                  </FormControl>
                  <div>&nbsp;</div>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="contact-label" htmlFor="contact-text">
                      Contact
                    </InputLabel>
                    <Input
                      type="text"
                      id="contact-text"
                      name="contact"
                      value={corporation.corporationContact}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "corporationContact")
                      }
                      required
                    />
                  </FormControl>
                </div>
              </Paper>
            </div>
            <div className="partRight">
              <Paper className="part partPaper">
                <Typography className="titre" variant="h6">
                  Site web
                </Typography>

                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <InputLabel id="url-label" htmlFor="url-text">
                    Url
                  </InputLabel>
                  <Input
                    type="text"
                    id="url-text"
                    name="url"
                    value={corporation.urlProduction}
                    onChange={(e) =>
                      updateCorporation(e.target.value, "urlProduction")
                    }
                    required
                  />
                </FormControl>

                <InputLabel id="primaryColor-label">
                  Couleur primaire
                </InputLabel>
                <div className="colorPickerContainer">
                  <div
                    className="colorPickerContainer-sample"
                    style={{
                      backgroundColor: corporation.primaryColor,
                    }}
                  ></div>
                  <input
                    type="text"
                    id="primaryColor-text"
                    name="primaryColor"
                    value={corporation.primaryColor}
                    onChange={(
                      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      updateCorporation(e.currentTarget.value, "primaryColor");
                      e.stopPropagation();
                    }}
                  />
                  <IconButton onClick={(event) => showColorPicker(event)}>
                    <Colorize />
                  </IconButton>
                </div>

                <InputLabel>Images</InputLabel>
                <div className="imagesPart">
                  <div className="imagePickerContainer">
                    <div className="imageDiv">
                      <img
                        ref={inputRefImageB2c}
                        src={corporation.b2cUrl}
                        onClick={() => {
                          setImageSelected("inputRefImageB2c");
                          if (inputRefUpload.current)
                            inputRefUpload.current.click();
                        }}
                      />
                    </div>
                    <span>Page de connexion</span>
                  </div>
                  <div className="imagePickerContainer imagePickerContainer-middle">
                    <div className="imageDiv">
                      <img
                        ref={inputRefImageBrand}
                        src={corporation.logoUrl}
                        onClick={() => {
                          setImageSelected("inputRefImageBrand");
                          if (inputRefUpload.current)
                            inputRefUpload.current.click();
                        }}
                      />
                    </div>
                    <span>Logo</span>
                  </div>
                  <div className="imagePickerContainer">
                    <div className="imageDiv">
                      <img
                        ref={inputRefImageFavIcon}
                        src={corporation.iconUrl}
                        onClick={() => {
                          setImageSelected("inputRefImageFavIcon");
                          if (inputRefUpload.current)
                            inputRefUpload.current.click();
                        }}
                      />
                    </div>
                    <span>Icône</span>
                  </div>
                </div>

                <input
                  type="file"
                  aria-label="add files"
                  style={{ display: "none" }}
                  ref={inputRefUpload}
                  multiple={true}
                  onChange={(evt: any) => {
                    handleImageSelected(evt);
                  }}
                />
              </Paper>
              <Paper className="part partPaper partPaperDottom">
                <Typography className="titre" variant="h6">
                  Localisation
                </Typography>

                <FormControl sx={{ marginBottom: "20px" }}>
                  <InputLabel id="address-label" htmlFor="address-text">
                    Adresse
                  </InputLabel>
                  <Input
                    type="text"
                    id="address-text"
                    name="address"
                    value={corporation.corporationAddress}
                    onChange={(e) =>
                      updateCorporation(e.target.value, "corporationAddress")
                    }
                    required
                  />
                </FormControl>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="latitude-label" htmlFor="latitude-text">
                      Latitude
                    </InputLabel>
                    <Input
                      type="text"
                      id="latitude-text"
                      name="latitude"
                      value={corporation.latitude}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "latitude")
                      }
                      required
                    />
                  </FormControl>
                  <div>&nbsp;</div>
                  <FormControl sx={{ marginBottom: "20px", width: "45%" }}>
                    <InputLabel id="longitude-label" htmlFor="longitude-text">
                      Longitude
                    </InputLabel>
                    <Input
                      type="text"
                      id="longitude-text"
                      name="longitude"
                      value={corporation.longitude}
                      onChange={(e) =>
                        updateCorporation(e.target.value, "longitude")
                      }
                      required
                    />
                  </FormControl>
                </div>
              </Paper>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
          >
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                marginBottom: "10px",
              }}
              endIcon={<DoneOutline />}
              onClick={handleValidateCorporation}
            >
              Enregistrer
            </Button>
            <Button
              style={{
                backgroundColor: "black",
                color: "white",
                marginBottom: "10px",
                marginLeft: "10px",
              }}
              endIcon={<DoneOutline />}
              onClick={showIntegrationPopup}
            >
              Lancer l'intégration
            </Button>
          </div>
          <Popover
            open={popupColorOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            onClose={() => hiddeColorPicker()}
          >
            <SketchPicker
              color={corporation.primaryColor}
              onChangeComplete={(color) =>
                updateCorporation(color.hex, "primaryColor")
              }
            />
          </Popover>
          <Popover
            open={popupIntegrationOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            onClose={() => hiddeIntegrationPopup()}
          >
            <ManagedCorporationIntegrationLauncher
              validationHandle={handleValidateCorporationWithWorkflowLaunch} />
          </Popover>
        </DialogContent>
      </Dialog>
    </>
  );
}
