import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";

const baseUrl = config.REACT_APP_BASE_API_URL
    + 'ManagedCorporations';

export function GetManagedCorporations(criteria, endFunctionHandler, errorHandler) {

    var url = baseUrl + "?freeSearch=" + criteria;
    ApiActions.getAPI(url)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler(datas.data);
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function DeleteManagedCorporation(item, endFunctionHandler, errorHandler) {
    const currentUrl = baseUrl + "/" + item.id;
    ApiActions.deleteAPI(currentUrl)
        .then((datas) => {
            if (endFunctionHandler != null)
                endFunctionHandler();
        })
        .catch((error) => {
            if (errorHandler != null)
                errorHandler(error);
        });
}

export function PostManagedCorporation(corporation, launchWorkflow, parameters, successHandler, errorHandler) {

    var form_data = new FormData();

    for (var key in corporation) {
        if (corporation[key] != undefined)
            form_data.append(key, corporation[key]);
    }
    if (parameters.environnements?.length > 0)
        form_data.append("Environments", parameters.environnements);
    if (parameters.workflowTypes?.length > 0)
        form_data.append("WorkflowTaskTypes", parameters.workflowTypes);
    const currentUrl = baseUrl + "?launchWorkflow=" + launchWorkflow;
    ApiActions.postMultipartAPI(currentUrl, form_data)
        .then((datas) => {
            if (successHandler != null)
                successHandler();
        })
        .catch((error) => {
            var errorMessage = "Une erreur s'est produite.";
            if (error.response.status == 400)
                errorMessage = "Les données sont invalides";
            if (errorHandler != null)
                errorHandler(errorMessage);
            console.error(error);
        });
}