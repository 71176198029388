import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { NoContent } from "components/common/commonComponent";
import { useState } from "react";
import "style/customerStyle.scss";

interface CustomerDetailProps {
  item: any;
  handleChange?: any;
  handleSave?: any;
  handleCancel?: any;
}

export function CustomerDetail(props: CustomerDetailProps) {
  function updateCustomer(value: any, field: string) {
    props.handleChange({ ...props.item, [field]: value });
  }

  function getTitle() {
    return "Modification de " + props.item.name || "";
  }

  function showDetail() {
  return props.item ?  (
      <Paper className="customerDetailPaper">
        <div className="customerDetailTitle">{getTitle()}</div>
        <div className="customerAddForm">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.item.isActive as boolean}
                onChange={(e: any) =>
                  updateCustomer(!props.item.isActive, "isActive")
                }
              />
            }
            label="Est actif"
          />
        </div>
        <div className="userAddActions">
          <Button
            variant="outlined"
            className="userButton"
            onClick={props.handleSave}
          >
            Valider
          </Button>
          <Button
            variant="outlined"
            className="userButton"
            onClick={props.handleCancel}
          >
            Annuler
          </Button>
        </div>
      </Paper>
    ) : (
      <></>
    );
  }

  return (
    <div className="customerDetailContainer">
      {props.item ? showDetail() : NoContent()}
    </div>
  );
}
