import { Site } from "model/Site";
import { SiteDetailProps } from "./siteDetail";
import { ColumnOption } from "model/ColumnOption";
import { useEffect, useState } from "react";
import { GetSiteActivities, PostSiteActivities } from "services/siteApiCaller";
import TableResult from "components/common/tableResult";
import { Button } from "@mui/material";
import "style/siteStyle.scss";

export function SiteDetail_Activity(props: SiteDetailProps) {
  const columOptionsLight = [
    new ColumnOption("Code activité", "siteActivityId", "siteActivityId"),
    new ColumnOption(
      "Libellé activité",
      "siteActivityName",
      "siteActivityName"
    ),
  ];
  const [activities, setActivities] = useState<any>([]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (props?.item?.identifier) {
      GetSiteActivities(props.item.identifier).then((res: any) => {
        setActivities(res);
      });
    }
  }, [props.item]);

  function handleChange(event: any) {
    if (event.target.checked) {
      setActivities([...activities, { siteActivityId: event.target.id }]);
    } else {
      let tempActivities = activities || [];
      tempActivities = activities.filter(
        (activity: any) => activity.siteActivityId !== event.target.id
      );
      setActivities(tempActivities);
    }
    setHasChanged(true);
  }

  function handleSave() {
    PostSiteActivities(props.item.identifier, activities).then((res: any) => {
      setHasChanged(false);
    });
  }

  function IsActivityChecked(activityCode: string) {
    const isChecked = activities?.some(
      (activity: any) => activity.siteActivityId === activityCode
    );
    return isChecked;
  }

  function showActivitiesCheckArray() {
    const activitiesRef = JSON.parse(
      localStorage.getItem("activities") || "[]"
    );
    return activitiesRef.map((activityRef: any) => {
      return (
        <div>
          <input
            type="checkbox"
            key={"chk" + activityRef.activityCode}
            id={activityRef.activityCode}
            name={activityRef.activityCode}
            checked={IsActivityChecked(activityRef.activityCode)}
            onChange={handleChange}
          />
          <label
            key={"lbl" + activityRef.activityCode}
            htmlFor={activityRef.label}
          >
            {"(" + activityRef.activityCode + ") " + activityRef.label}
          </label>
        </div>
      );
    });
  }

  return (
    <>
      <div className="siteDetailActivityContainer">
        <div className="paperDiv">
          <div className="titleForm">Gestion des Activités</div>

          {showActivitiesCheckArray()}
        </div>
      </div>
      <div className="siteDetailAction">
        <Button
          variant="contained"
          className={
            "siteButton" +
            (hasChanged ? " siteButtonVisible" : " siteButtonInvisible")
          }
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}
