import * as ApiActions from "./apiCaller";
import { config } from "config";
import axios from "axios";
import { WorkflowSearchCriteria } from "model/WorkflowSearchCriteria";
import { Workflow } from "model/Workflow";
import { WorkflowTask } from "model/WorkflowTask";

export async function getWorkflows(parameters: WorkflowSearchCriteria) {
  var baseUrl = config.REACT_APP_BASE_API_URL + "Workflows" + toUrlParameters(parameters);

  var result = await ApiActions.getAPI(baseUrl);
  let data = Object.assign([], result.data);
  return data as Workflow[];
}

export async function getWorkflowCount() {
  var baseUrl = config.REACT_APP_BASE_API_URL + "Workflows/Count";

  var result = await ApiActions.getAPI(baseUrl);
  return result.data as number;
}

export async function getWorkflowTasks(id?: string | null) {
  var baseUrl = config.REACT_APP_BASE_API_URL + "Workflows/" + id + "/Tasks";

  var result = await ApiActions.getAPI(baseUrl);
  return result.data as WorkflowTask[];
}

function toUrlParameters(searchCriteria: WorkflowSearchCriteria) {
  var urlParameters = "?";
  if (searchCriteria.isActive) urlParameters += "isActive=true";
  else urlParameters += "isActive=false";
  return urlParameters;
}
