import { Check, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import TableInfinite from "components/common/tableInfinite";
import TableResult from "components/common/tableResult";
import { ColumnOption } from "model/ColumnOption";
import { MapPermissionRole } from "services/mapper";
import useWindowDimensions from "services/windowDimensions";

interface UserListProps {
  items: any;
  pagingParameter: any;
  pageChangingHandler: any;
  onRowSelected?: any;
  selectedItem?: any;
  setSelectedItem?: any;
}

export function UserList(props: UserListProps) {
  function showHeader() {
    return (
      <div className="row">
        <div className="idCell">Statut</div>
        <div className="idCell">Id</div>
        <div className="nameCell">Email</div>
        <div className="nameCell">Nom</div>
        <div className="nameCell">Prénom</div>
        <div className="nameCell">Rôle</div>
        <div className="nameCell">Client</div>
      </div>
    );
  }
  function showItem(item: any) {
    return (
      <div className="row">
        {item.isActive ? <Check sx={{ fontSize: 16 }} /> : <RemoveCircleOutline sx={{ fontSize: 16 }} />}
        <div className="idCell">{item.id}</div>
        <div className="nameCell">{item.email}</div>
        <div className="nameCell">{item.lastName}</div>
        <div className="nameCell">{item.firstName}</div>
        <div className="nameCell">{item.roleId}</div>
        <div className="nameCell">{item.customerMdmId}</div>
      </div>
    );
  }

  const columOptions = [
    new ColumnOption("", "isActive", "p.is_active", (item: any) => {
      if (item.isActive)
        return (
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => {
              item.isActive = false;
              //postPermission(item);
            }}
          >
            <Check sx={{ fontSize: 16 }} />
          </IconButton>
        );
      else
        return (
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => {
              item.isActive = true;
              //postPermission(item);
            }}
          >
            <RemoveCircleOutline sx={{ fontSize: 16 }} />
          </IconButton>
        );
    }),
    new ColumnOption("Id", "id", "p.id"),
    new ColumnOption("Email", "email", "p.user_id"),
    new ColumnOption("Nom", "lastName", "u.last_name"),
    new ColumnOption("Prénom", "firstName", "u.first_name"),
    new ColumnOption("Role", "roleId", "p.role_id", (item: any) => {
      return MapPermissionRole(item.roleId);
    }),
    new ColumnOption("Customer", "customerMdmId", "p.client_company_id"),
  ];

  var dim = useWindowDimensions();
  var tableHeight = 600;
  if (dim.height > 900) tableHeight = 800;

  return (
    <div
      style={{
        height: tableHeight + "px",
        width: "100%",
      }}
    >
      <TableResult
        headers={columOptions}
        items={props.items.items}
        count={props.items.count}
        pagingParam={props.pagingParameter}
        onPageChange={props.pageChangingHandler}
        onRowSelected={props.onRowSelected}
        idProperty="id"
        selectedItem={props.selectedItem}
        setSelectedItem={props.setSelectedItem}
      />
    </div>
  );
}
