import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const initialState = {
  name: null,
  preferredName: null,
  isAuthenticated: false,
  initialized: false,
  idToken: null,
  accessToken: null,
  state: AuthenticationState.Unauthenticated,
};

export default function authentReducer(state = initialState, action: any) {
  switch (action.type) {
    case AuthenticationActions.AcquiredIdTokenSuccess:
      return {
        ...state,
        idToken: action.idToken,
        name: action.idToken.name,
        preferredName: action.idToken.preferredName,
      };
    case AuthenticationActions.AcquiredAccessTokenSuccess:
      return {
        ...state,
        accessToken: action.accessToken,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};