import { Add, FileDownload, FileUpload, Search } from "@mui/icons-material";
import { IconButton, Input, Tooltip } from "@mui/material";
import "style/corporation.scss";

interface ManagedCorporationSearchProps {
  handleSearch?: any;
  handleAdd?: any;
  handleImport?: any;
  handleExport?: any;
  changeCriterias?: any;
  criterias?: any;
}

export function ManagedCorporationSearch(props: ManagedCorporationSearchProps) {
  return (
    <div className="coroporationSearchContainer">
      <form onSubmit={props.handleSearch}>
        <Input
          value={props.criterias || ""}
          onChange={(e: any) => {
            props.changeCriterias(e.target.value);
          }}
        />
        <Tooltip title="Recherche libre" placement="top">
          <IconButton onClick={props.handleSearch}>
            <Search />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajouter" placement="top">
          <IconButton onClick={props.handleAdd}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title="Importer" placement="top">
          <IconButton onClick={(event:any) => props.handleImport(event)}>
            <FileUpload />
          </IconButton>
        </Tooltip>
      </form>
    </div>
  );
}
