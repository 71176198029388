// create a new component called WorkflowListItem
// this component will be used to display a single workflow item

import { Expand, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, IconButton, Paper } from "@mui/material";
import { Workflow } from "model/Workflow";
import { WorkflowTask } from "model/WorkflowTask";
import { useEffect, useState } from "react";
import "style/workflow.scss";
import { WorkflowTaskList } from "./workflowTaskList";
import { getWorkflowTasks } from "services/workflowApiCaller";
import { ShowStatus } from "components/common/commonComponent";

interface WorkflowItemProps {
  workflow: Workflow;
}

export function WorkflowItem(props: WorkflowItemProps) {
  const [expanded, setExpanded] = useState(false);
  const[workflowTasks, setWorkflowTasks] = useState([] as WorkflowTask[]);

  useEffect(() => {
    if(expanded){
        getWorkflowTasks(props.workflow.id).then((result) => {
            setWorkflowTasks(result);
            }
        );
    }
  }, [expanded]);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <Paper className="workflowListItemPaper">
        <div className="workflowListItemContainer">
      <IconButton onClick={handleExpandClick}>
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <div className="workflowListItemTitle">{props.workflow.label}</div>
      <Box sx={{ flexGrow: 1 }} />
      <div className="workflowListItemStatus">
          {ShowStatus(props.workflow)}</div>
      </div>
      {expanded ? <WorkflowTaskList workflowTasks={workflowTasks} /> : <></>}
    </Paper>
  );
}
