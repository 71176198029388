
export function MapPermissionRole(roleId: number) {

    const storageValue = localStorage.getItem("roles");
    const item = JSON.parse(storageValue ?? "");
    if(item)
    {
        const role = item.find((r: any) => r.id === roleId);
        if(role)
        {
            return role.label;
        }
    }
}