import { TextField } from "@mui/material";
import { Site } from "model/Site";
import { SiteDetailProps } from "./siteDetail";
import { CustomInput } from "components/common/customInput";
import 'style/siteStyle.scss';
import { useState } from "react";
import { CustomCheckbox } from "components/common/customCheckbox";

export function SiteDetail_GeneralInformation(props: SiteDetailProps) {

  return (
    <>
    <div className="titleForm">Informations Générales</div>
      <div className="detailFormContainer">
        <CustomInput
          label="Id"
          value={props?.item?.identifier || ""}
          handleChange={(e: any) =>
            props.handleChange(e.currentTarget.value, "identifier")
          }
        />
        <CustomInput
          label="Name"
          value={props?.item?.name || ""}
          handleChange={(e: any) =>
            props.handleChange(e.currentTarget.value, "name")
          }
        />
        <CustomCheckbox
          label="Actif"
          value={props?.item?.isActive || false}
          handleChange={(e: any) =>
            props.handleChange(e.currentTarget.checked, "isActive")
          }
        />
      </div>
    </>
  );
}
