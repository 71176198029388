import { Box, Button, Popover, Toolbar, Tooltip } from "@mui/material";
import { AddBusinessRounded } from "@mui/icons-material";
import { ManagedCorporationCreation } from "./managedCorporationCreation";
import {
  GetManagedCorporations,
  PostManagedCorporation,
  DeleteManagedCorporation,
} from "services/managedCorporationApiCaller";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import WaitingComponent from "components/common/waitingComponent";
import { ManagedCorporationVignette } from "./managedCorporationVignette";
import { ManagedCorporation } from "model/ManagedCorporation";
import ScrollBar from "react-perfect-scrollbar";
import useWindowDimensions from "services/windowDimensions";
import { ManagedCorporationSearch } from "./managedCorporationSearch";
import { FileUploadManager } from "components/common/fileUploadManager";
import { FileUploader } from "react-drag-drop-files";

export function ManagedCorporationList({}) {
  const [managedCorporations, setManagedCorporations] = useState<
    ManagedCorporation[]
  >([]);
  const [selectedCorporation, setSelectedCorporation] =
    useState<ManagedCorporation>(new ManagedCorporation());
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(true);
  const [showDrawerAdd, setShowDrawerAdd] = useState(false);
  const [freeSearch, setFreeSearch] = useState<string | null>("");
  var [popupFileUpload, setPopupFileUpload] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  var dim = useWindowDimensions();
  var vignetteListHeight = 600;
  if (dim.height > 900) vignetteListHeight = 700;

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
      GetCorporations();
    }
  }, [isFirstTime]);

  function GetCorporations() {
    setIsLoadingStatus(true);
    GetManagedCorporations(
      freeSearch,
      (datas: ManagedCorporation[]) => {
        setManagedCorporations(datas);
        setIsLoadingStatus(false);
      },
      (error: any) => {
        setManagedCorporations([]);
        setIsLoadingStatus(false);
      }
    );
  }

  function AddCorporation() {
    var newCorporation = new ManagedCorporation();
    setSelectedCorporation(newCorporation);
    setShowDrawerAdd(true);
  }

  function EditCorporation(item: ManagedCorporation) {
    setSelectedCorporation(item);
    setShowDrawerAdd(true);
  }

  function createOrUpdateCorporation(
    item: ManagedCorporation,
    launchWorkflow: boolean,
    parameters: any
  ) {
    setShowDrawerAdd(false);
    setIsLoadingStatus(true);
    PostManagedCorporation(
      item,
      launchWorkflow,
      parameters,
      () => {
        GetCorporations();
      },
      () => {
        GetCorporations();
      }
    );
  }

  function DeleteCorporation(item: ManagedCorporation) {
    setIsLoadingStatus(true);
    DeleteManagedCorporation(
      item,
      () => {
        GetCorporations();
      },
      (error: any) => {
        setIsLoadingStatus(false);
      }
    );
  }

  function showCorporations() {
    return managedCorporations.map((item: ManagedCorporation) => {
      return (
        <ManagedCorporationVignette
          key={item.id}
          item={item}
          editHandler={EditCorporation}
          deleteHandler={DeleteCorporation}
        />
      );
    });
  }

  if (isLoadingStatus) return <WaitingComponent message={"Chargement"} />;
  else
    return (
      <>
        <Popover
          open={popupFileUpload}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          onClose={() => setPopupFileUpload(false)}
        >
          <div>
            <label>Importer un fichier</label>
            <FileUploader name="file" types={["xlsx"]} />
          </div>
        </Popover>
        <ManagedCorporationSearch
          criterias={freeSearch}
          handleSearch={() => {
            GetCorporations();
          }}
          changeCriterias={(value: string) => setFreeSearch(value)}
          handleAdd={() => AddCorporation()}
          handleImport={(event: any) => {
            setPopupFileUpload(true);
            setAnchorEl(event.currentTarget);
          }}
        />
        <Toolbar>
          <div>
            <ScrollBar style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  height: vignetteListHeight + "px",
                }}
              >
                {showCorporations()}
              </div>
            </ScrollBar>
          </div>
        </Toolbar>
        <ManagedCorporationCreation
          item={selectedCorporation}
          open={showDrawerAdd}
          handleCreation={createOrUpdateCorporation}
          handleClose={() => setShowDrawerAdd(false)}
        />
      </>
    );
}
