import { Engineering } from "@mui/icons-material";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { Link } from "@reach/router";
import Vignette from "components/dashboard/vignette";
import MainLayout from "layouts/mainLayout";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions/siteActions";

function Dashboard({ }) {


  return (
    <>
      <MainLayout>
        <Box
          alignItems="center"
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          marginTop="150px">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Box alignContent="center">
              <Engineering sx={{ fontSize: '250px' }} />
            </Box>
            <Box alignContent="center">
              <Typography sx={{ fontSize: '50px' }}>En cours de réalisation...</Typography>
            </Box>
            
            {/* <Box
              justifyContent="left">
              <h1>Raccourcis</h1>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
            >
              <Vignette text="Ajout d'un compte" icon={null} />
              <Vignette text="Ajout d'un site" icon={null} />
            </Box> */}
          </Box>
        </Box>
      </MainLayout>
    </>
  );
}


const mapStateToProps = (state: any) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);