import {
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  GetPermissions,
  PostPermission,
  convertPermissionToVuser,
  convertVuserToPermission,
} from "services/userApiCaller";
import { UserList } from "./userList";
import { UserToolBar } from "./userToolBar";
import { Close } from "@mui/icons-material";
import WaitingComponent from "components/common/waitingComponent";
import "style/userStyle.scss";
import useWindowDimensions from "services/windowDimensions";
import { ClickAwayListener } from "@material-ui/core";
import { UserAdd } from "./userAdd";
import { UserDetail } from "./userDetail";
import { VUser } from "model/VUser";

interface UserListDialogParams {
  sjCode: string;
  open: boolean;
  handleClose: () => void;
}

export default function UserListDialog({
  sjCode,
  open,
  handleClose,
}: UserListDialogParams) {
  var dim = useWindowDimensions();
  var pageSize = 15;
  if (dim.height > 900) pageSize = 17;

  const [snackBarProperties, setSnackBarProperties] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [criterias, setCriterias] = useState({ sjId: sjCode, freeSearch: "" });
  const [pagingParameters, setPagingParameters] = useState({
    currentPage: 0,
    pageSize: pageSize,
    currentSortId: "p.id",
    isAsc: false,
  });
  const [datas, setDatas] = useState([]);
  const [layoutAndDetailProperties, setLayoutAndDetailProperties] =
    useState<any>({
      listPartWidth: 90,
      detailPartWidth: 0,
      selectedItem: null as VUser | null,
    });

  useEffect(() => {
    handleSearch();
  }, [pagingParameters]);

  function handleSearch() {
    setIsLoading(true);
    GetPermissions(
      pagingParameters,
      criterias,
      (datas: any) => {
        setDatas(datas);
        setIsLoading(false);
      },
      (error: any) => {
        console.log(error);
        setIsLoading(false);
      }
    );
  }

  function handleCreate() {
    setLayoutAndDetailProperties({
      listPartWidth: 75,
      detailPartWidth: 25,
      selectedItem: { sj: sjCode },
    });
  }

  function handleSave() {
    const permission = convertVuserToPermission(
      layoutAndDetailProperties.selectedItem
    );
    PostPermission(permission)
      .then(() => {
        setSnackBarProperties({
          isOpen: true,
          message: "Utilisateur enregistré",
          severity: "success",
        });
        handleSearch();
      })
      .catch((error: any) => {
        setSnackBarProperties({
          isOpen: true,
          message: error.response.data.detail,
          severity: "error",
        });
        console.log(error);
      });
  }

  function detailChanged(item: any) {
    setLayoutAndDetailProperties({
      ...layoutAndDetailProperties,
      selectedItem: convertPermissionToVuser(item),
    });
  }

  function handleImport() {
    console.log("handleImport");
  }

  function handleExport() {
    console.log("handleExport");
  }

  function changeCriterias(key: string, value: any) {
    setCriterias({ ...criterias, [key]: value });
  }

  function handlePageChange(param: any) {
    setPagingParameters(param);
  }

  function adjustLayout(item: any) {
    if (item)
      setLayoutAndDetailProperties({
        listPartWidth: 75,
        detailPartWidth: 25,
        selectedItem: item,
      });
    else
      setLayoutAndDetailProperties({
        listPartWidth: 90,
        detailPartWidth: 0,
        selectedItem: item,
      });
  }

  function getDetail() {
    if (layoutAndDetailProperties.selectedItem)
      return (
        <UserDetail
          item={convertVuserToPermission(
            layoutAndDetailProperties.selectedItem
          )}
          handleChange={detailChanged}
          handleSave={handleSave}
          handleCancel={() =>
            setLayoutAndDetailProperties({
              detailPartWidth: 0,
              listPartWidth: 90,
              selectedItem: null,
            })
          }
        />
      );
    else return <></>;
  }

  function getContent() {
    if (isLoading) {
      return (
        <WaitingComponent message={"Utilisateurs en cours de chargement"} />
      );
    } else {
      return (
        <>
          <div
            className="userListPart"
            style={{ width: layoutAndDetailProperties.listPartWidth + "%" }}
          >
            <UserToolBar
              handleSearch={handleSearch}
              handleCreate={handleCreate}
              handleImport={handleImport}
              handleExport={handleExport}
              changeCriterias={changeCriterias}
              criterias={criterias}
            />
            <UserList
              items={datas}
              pagingParameter={pagingParameters}
              pageChangingHandler={(event: any, param: any) =>
                handlePageChange(param)
              }
              onRowSelected={adjustLayout}
              selectedItem={layoutAndDetailProperties.selectedItem}
              setSelectedItem={(item: any) => {
                setLayoutAndDetailProperties({
                  ...layoutAndDetailProperties,
                  selectedItem: item,
                });
              }}
            />
          </div>
          <div
            className="userDetailPart"
            style={{
              width: layoutAndDetailProperties.detailPartWidth + "%",
              visibility:
                layoutAndDetailProperties.detailPartWidth == 0
                  ? "hidden"
                  : "visible",
            }}
          >
            {getDetail()}
          </div>
        </>
      );
    }
  }

  function handleUserCreation(permission: any) {
    console.log("handleUserCreation");
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className="dialogUser"
        disableEnforceFocus
      >
        <Toolbar className="toolbarUser">
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Liste des utilisateurs de la société {sjCode}
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <Close sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
        <div className="userDialogContent">{getContent()}</div>
        <Snackbar
          style={{ color: "black" }}
          open={snackBarProperties.isOpen}
          onClose={() =>
            setSnackBarProperties({ isOpen: false, message: "", severity: "" })
          }
          autoHideDuration={10000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() =>
              setSnackBarProperties({
                isOpen: false,
                message: "",
                severity: "",
              })
            }
            severity={snackBarProperties.severity as AlertColor}
          >
            {snackBarProperties.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
}
