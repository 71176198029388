import {
  Add,
  FileDownload,
  FileUpload,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  Search,
} from "@mui/icons-material";
import { IconButton, Input, Popover, Popper, Tooltip } from "@mui/material";
import { Customer } from "model/Customer";
import React from "react";
import { useState } from "react";
import "style/customerStyle.scss";
import { CustomerSearchCriteria } from "./customerSearchCriteria";

interface CustomerToolBarProps {
  handleSearch?: any;
  handleImport?: any;
  handleExport?: any;
  changeCriterias?: any;
  criterias?: any;
}

export function CustomerToolBar(props: CustomerToolBarProps) {
  function showSearchCriteriasDetailButton() {
    if (props.criterias.isComplexSearch)
      return (
        <Tooltip title="Recherche simple" placement="left">
          <IconButton
            onClick={() => props.changeCriterias("isComplexSearch", false)}
          >
            <KeyboardArrowLeftRounded />
          </IconButton>
        </Tooltip>
      );
    else
      return (
        <Tooltip title="Recherche avancée" placement="left">
          <IconButton
            onClick={() => props.changeCriterias("isComplexSearch", true)}
          >
            <KeyboardArrowRightRounded />
          </IconButton>
        </Tooltip>
      );
  }

  function showSearchCriterias() {
    if (props.criterias.isComplexSearch)
      return (
        <CustomerSearchCriteria
          criterias={props.criterias}
          changeCriterias={props.changeCriterias}
        />
      );
    else
      return (
        <Input
          value={props.criterias.freeSearch}
          onChange={(e: any) => {
            props.changeCriterias("freeSearch", e.target.value);
          }}
        />
      );
  }

  return (
    <div className="customerSearchMainContainer">
      {showSearchCriteriasDetailButton()}
      <form
        className="customerFormSearchCriteria"
        onSubmit={props.handleSearch}
      >
        {showSearchCriterias()}
        <div style={{marginTop:"10px"}}>
          <Tooltip title="Rechercher" placement="top">
            <IconButton onClick={props.handleSearch}>
              <Search />
            </IconButton>
          </Tooltip>
          <Tooltip title="Exporter" placement="top">
            <IconButton onClick={() => props.handleExport}>
              <FileDownload />
            </IconButton>
          </Tooltip>
          <Tooltip title="Importer" placement="top">
            <IconButton onClick={() => props.handleImport}>
              <FileUpload />
            </IconButton>
          </Tooltip>
        </div>
      </form>
    </div>
  );
}
