// create a component to display a single workflow task item
import { WorkflowTask } from "model/WorkflowTask";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { ShowStatus } from "components/common/commonComponent";

interface WorkflowTaskListItemProps {
  workflowTask: WorkflowTask;
}

export function WorkflowTaskItem(props: WorkflowTaskListItemProps) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="workflowTaskListItemContainer">
      <ArrowForward />
      <div className="workflowTaskListItemTitle">
        {props.workflowTask.taskLabel}
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <div className="workflowTaskListItemStatus">
          {ShowStatus(props.workflowTask)}
      </div>
    </div>
  );
}
