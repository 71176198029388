import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";


function WaitingComponent({ message }) {

    return (
        <Box
            alignItems="center"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            marginTop="150px">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center">
                <Box alignContent="center">
                    <ClipLoader color="#000000" loading="true" size={150} />
                </Box>
                <Box alignContent="center">
                    <Typography sx={{ fontSize: '30px', color:'#000000' }}>{message}</Typography>
                </Box>
            </Box>
        </Box>
    );

}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(WaitingComponent);