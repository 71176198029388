import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { NoContent } from "components/common/commonComponent";
import { useState } from "react";
import "style/userStyle.scss";

interface UserDetailProps {
  item: any;
  handleChange?: any;
  handleSave?: any;
  handleCancel?: any;
}

export function UserDetail(props: UserDetailProps) {
  function updatePermission(value: any, field: string) {
    props.handleChange({ ...props.item, [field]: value });
  }

  function getSjOptions() {
    var portalSjs = JSON.parse(localStorage.getItem("portalSjs") || "[]");
    return portalSjs;
  }
  function getRoleOptions() {
    var roles = JSON.parse(localStorage.getItem("roles") || "[]");

    if (
      props.item.clientId !== null &&
      props.item.clientId !== undefined &&
      props.item.clientId !== 0
    )
      roles = roles.filter((item: any) => item.id > 1);
    const options = roles.map((role: any) => {
      return {
        label: role.label,
        id: role.id,
      };
    });
    return options;
  }
  function getSelectedRoleOption() {
    var roles = JSON.parse(localStorage.getItem("roles") || "[]");
    var currentRole = roles.find((item: any) => item.id == props.item.roleId);
    if (currentRole != null && currentRole != undefined)
      return {
        label: currentRole.label,
        id: currentRole.id,
      };
    else return null;
  }
  function getSelectedSjOption() {
    var portalSjs = JSON.parse(localStorage.getItem("portalSjs") || "[]");
    var currentSj = portalSjs.find((item: any) => item.id == props.item.sjId);
    if (currentSj != null && currentSj != undefined)
      return {
        label: currentSj.label,
        id: currentSj.id,
      };
    else return null;
  }

  function getTitle() {
    if (
      props.item.id == null ||
      props.item.id == undefined ||
      props.item.id == 0
    )
      return "Ajouter un utilisateur";
    else return "Modifier un utilisateur";
  }

  function isClientPartVisible() {
    if (
      props.item &&
      props.item.roleId &&
      (props.item.roleId == 1 || props.item.roleId == 5)
    )
      return false;
    else return true;
  }

  function showDetail() {
  return props.item ?  (
      <Paper className="userDetailPaper">
        <div className="userDetailTitle">{getTitle()}</div>
        <div className="userAddForm">
          <TextField
            required
            label="Email"
            variant="outlined"
            value={props.item.userId || ""}
            onChange={(e) => updatePermission(e.currentTarget.value, "userId")}
            className="userTextFieldStyle"
          />
          <Autocomplete
            id="ac-sj"
            readOnly
            disablePortal
            onChange={(e, value: any) => {
              updatePermission(value.id, "sjId");
            }}
            options={getSjOptions()}
            defaultValue={getSelectedSjOption()}
            value={getSelectedSjOption()}
            className="userAutoCompleteStyle"
            renderInput={(params) => (
              <TextField
                required
                variant="outlined"
                className="textFieldStyle"
                {...params}
                label="Sj"
              />
            )}
          />
          <Autocomplete
            id="ac-role"
            disablePortal
            onChange={(e, value: any) => {
              updatePermission(value.id, "roleId");
            }}
            options={getRoleOptions()}
            defaultValue={getSelectedRoleOption()}
            value={getSelectedRoleOption()}
            className="userAutoCompleteStyle"
            renderInput={(params) => (
              <TextField
                required
                variant="outlined"
                className="textFieldStyle"
                {...params}
                label="Role"
              />
            )}
          />
          <TextField
            label="Client Id"
            variant="outlined"
            value={
              props.item.clientId && props.item.clientId > 0
                ? props.item.clientId
                : ""
            }
            onChange={(e) =>
              updatePermission(e.currentTarget.value, "clientId")
            }
            className="userTextFieldStyle"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.item.isActive as boolean}
                onChange={(e: any) =>
                  updatePermission(!props.item.isActive, "isActive")
                }
              />
            }
            label="Est actif"
          />
        </div>
        <div className="userAddActions">
          <Button
            variant="outlined"
            className="userButton"
            onClick={props.handleSave}
          >
            Valider
          </Button>
          <Button
            variant="outlined"
            className="userButton"
            onClick={props.handleCancel}
          >
            Annuler
          </Button>
        </div>
      </Paper>
    ) : (
      <></>
    );
  }

  return (
    <div className="userDetailContainer">
      {props.item ? showDetail() : NoContent()}
    </div>
  );
}
