import { CustomInput } from "components/common/customInput";
import { Site } from "model/Site";
import { SiteDetailProps } from "./siteDetail";
import {
  AuthenticationType,
  AzureMap,
  AzureMapsContext,
  AzureMapsProvider,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from "react-azure-maps";
import "style/site/siteDetailAddress.scss";
import { useContext, useEffect, useState } from "react";
import MapController from "components/common/mapControler";

export function SiteDetail_Address(props: SiteDetailProps) {
  const { mapRef, isMapReady } =
    useContext<IAzureMapsContextProps>(AzureMapsContext);

  return (
    <>
      <div className="titleForm">Gestion de l'adresse</div>
      <div className="detailFormContainer siteAddressContainer">
        <div className="siteAddressForm">
          <CustomInput
            label="Téléphone"
            value={props?.item?.commercialPhone || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "commercialPhone")
            }
          />
          <CustomInput
            label="Adresse Ligne 1"
            value={props?.item?.address_Line1 || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "address_Line1")
            }
          />
          <CustomInput
            label="Adresse ligne 2"
            value={props?.item?.address_Line2 || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "address_Line2")
            }
          />
          <CustomInput
            label="Code Postal"
            value={props?.item?.address_ZipCode || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "address_ZipCode")
            }
          />
          <CustomInput
            label="Ville"
            value={props?.item?.address_City || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "address_City")
            }
          />
          <CustomInput
            label="Pays"
            value={props?.item?.address_CountryCode || ""}
            handleChange={(e: any) =>
              props.handleChange(e.currentTarget.value, "address_CountryCode")
            }
          />
          <CustomInput
            label="latitude"
            value={props?.item?.gpsCoordinates?.latitude || ""}
            handleChange={(e: any) =>
              props.handleChange(
                e.currentTarget.value,
                "gpsCoordinates.latitude"
              )
            }
          />
          <CustomInput
            label="longitude"
            value={props?.item?.gpsCoordinates?.longitude || ""}
            handleChange={(e: any) =>
              props.handleChange(
                e.currentTarget.value,
                "gpsCoordinates.longitude"
              )
            }
          />
        </div>
        <div className="siteAddressGeo">
          <div className="siteAddressMap">
            <AzureMapsProvider>
              <MapController
                latitude={
                  (props?.item?.gpsCoordinates?.latitude as number) ?? 0
                }
                longitude={
                  (props?.item?.gpsCoordinates?.longitude as number) ?? 0
                }
              />
            </AzureMapsProvider>
          </div>
        </div>
      </div>
    </>
  );
}
