import { Add, FileDownload, FileUpload, Search } from "@mui/icons-material";
import { IconButton, Input, Popover, Popper, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import "style/userStyle.scss";

interface UserToolBarProps {
  handleSearch?: any;
  handleCreate?: any;
  handleImport?: any;
  handleExport?: any;
  changeCriterias?: any;
  criterias?: any;
}

export function UserToolBar(props: UserToolBarProps) {

  return (
    <div className="userSearchMainContainer">
      <form onSubmit={props.handleSearch}>
        <Input
          value={props.criterias.freeSearch}
          onChange={(e: any) => {
            props.changeCriterias("freeSearch", e.target.value);
          }}
        />
        <Tooltip title="Recherche libre" placement="top">
          <IconButton onClick={props.handleSearch}>
            <Search />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajouter" placement="top">
          <IconButton onClick={props.handleCreate}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exporter" placement="top">
          <IconButton onClick={() => props.handleExport}>
            <FileDownload />
          </IconButton>
        </Tooltip>
        <Tooltip title="Importer" placement="top">
          <IconButton onClick={() => props.handleImport}>
            <FileUpload />
          </IconButton>
        </Tooltip>
      </form>
    </div>
  );
}
