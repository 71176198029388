import {
  Business,
  Check,
  ContentCopy,
  DeleteOutlineOutlined,
  DeviceUnknown,
  Error,
  ModeEditOutline,
  Pending,
  PeopleOutline,
  Place,
  Start,
} from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import "style/corporation.scss";
import { ManagedCorporation } from "model/ManagedCorporation";
import { useState } from "react";
import CustomerListDialog from "components/customers/customerListDialog";
import UserListDialog from "components/users/userListDialog";
import SiteListDialog from "components/sites/siteListDialog";
import { ConfirmationDialog } from "components/common/confirmationDialog";
import { ShowStatus } from "components/common/commonComponent";

interface ManagedCorporationVignetteProps {
  item: ManagedCorporation;
  editHandler: any;
  deleteHandler: any;
}

export function ManagedCorporationVignette({
  item,
  editHandler,
  deleteHandler,
}: ManagedCorporationVignetteProps) {
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
  const [openSiteDialog, setOpenSiteDialog] = useState(false);
  const [openConfirmDelete, setpenConfirmDelete] = useState(false);


  function ShowConfirmDelete() {
    return (
      <ConfirmationDialog
        open={openConfirmDelete}
        title="Supprimer une société"
        content="Etes-vous sûr de vouloir supprimer cette société ?"
        handleClose={() => setpenConfirmDelete(false)}
        handleConfirm={() => {
          setpenConfirmDelete(false);
          deleteHandler(item);
        }}
      />
    );
  }

  function ShowUserDialog() {
    if (openUserDialog)
      return (
        <UserListDialog
          open={openUserDialog}
          handleClose={() => setOpenUserDialog(false)}
          sjCode={item.sjCode}
        />
      );
    else return <></>;
  }

  function ShowCustomerDialog() {
    if (openCustomerDialog)
      return (
        <CustomerListDialog
          open={openCustomerDialog}
          handleClose={() => setOpenCustomerDialog(false)}
          sjCode={item.sjCode}
        />
      );
    else return <></>;
  }

  function ShowSiteDialog() {
    if (openSiteDialog)
      return (
        <SiteListDialog
          open={openSiteDialog}
          handleClose={() => setOpenSiteDialog(false)}
          sjCode={item.sjCode}
        />
      );
    else return <></>;
  }

  return (
    <>
      <div className="corporation-vignette">
        <div className="corporation-vignette-header">
          <img src={item.iconUrl} />
          <span>{item.corporationName}</span>
          <br></br>
          <span>({item.sjCode})</span>
        </div>
        <div className="corporation-vignette-body">
          <div className="div_icon"  onClick={() => setOpenUserDialog(true)}>
            <Tooltip title="Afficher les utilisateurs" placement="top">
              <IconButton size="large">
                <PeopleOutline className="svg_icons" />
              </IconButton>
            </Tooltip>
            <div>Permissions</div>
          </div>
          <div className="div_icon" onClick={() => setOpenSiteDialog(true)}>
            <Tooltip title="Afficher les sites" placement="top">
              <IconButton size="large">
                <Place className="svg_icons" />
              </IconButton>
            </Tooltip>
            <div>Sites</div>
          </div>
          <div className="div_icon" onClick={() => setOpenSiteDialog(true)}>
            <Tooltip title="Afficher les documents" placement="top">
              <IconButton size="large" >
                <ContentCopy className="svg_icons" />
              </IconButton>
            </Tooltip>
            <div>Documents</div>
          </div>
          <div className="div_icon" onClick={() => setOpenCustomerDialog(true)}>
            <Tooltip title="Afficher les clients" placement="top">
              <IconButton size="large" >
                <Business className="svg_icons" />
              </IconButton>
            </Tooltip>
            <div>Clients</div>
          </div>
        </div>
        <div className="corporation-vignette-actions">
          <Tooltip title="Détails" placement="top">
            <IconButton size="small" onClick={() => editHandler(item)}>
              <ModeEditOutline fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Supprimer" placement="top">
            <IconButton size="small" onClick={() => setpenConfirmDelete(true)}>
              <DeleteOutlineOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <div style={{ flexGrow: 1 }}></div>
          {ShowStatus(item)}
        </div>
      </div>
      {ShowUserDialog()}
      {ShowSiteDialog()}
      {ShowConfirmDelete()}
      {ShowCustomerDialog()}
    </>
  );
}
