import { Close } from "@mui/icons-material";
import { Button, IconButton, Popper } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import "style/uploader.scss";

interface FileUploadManagerProps {
  isopen: boolean;
  anchorRef: any;
  handleChange?: any;
  handleCancel?: any;
  title?: string;
}

export function FileUploadManager(props: FileUploadManagerProps) {
  function handleChange(files: any) {
    console.log(files);
  }

  return (
    <Popper
      className="popperUpload"
      open={props.isopen}
      anchorEl={props.anchorRef.current}
      placement="bottom-start"
    >
      <div className="mainContainer">
        <div>
          <h3>{props.title ?? "Chargement de fichier"}</h3>
          <IconButton onClick={props.handleCancel}>
            <Close />
          </IconButton>
        </div>

        <FileUploader
          handleChange={handleChange}
          name="file"
          types={["xlsx"]}
        />

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleCancel}
          >
            Valider
          </Button>
        </div>
      </div>
    </Popper>
  );
}
