import 'style/common.scss';

interface CustomInputProps {
  label: string;
  value: string;
  handleChange: any;
  classname?: string;
}
export function CustomInput(props: CustomInputProps) {
  return (
    <div className={"customInputContainer " + (props.classname ?? "")}>
      <div className="customInputLabel">
        <label>{props.label}</label>
      </div>
      <div className="customInputValue">
        <input
          type="text"
          value={props.value || ""}
          onChange={props.handleChange}
        />
      </div>
    </div>
  );
}
