import { useState } from "react";
import { SiteDetail_ContainerGeneral } from "./siteDetail_ContainerGeneral";
import "style/siteStyle.scss";
import { SiteDetail_Waste } from "./siteDetail_Waste";
import { SiteDetail_Activity } from "./siteDetail_Activity";
import { Button } from "@mui/material";

export interface SiteDetailProps {
  item: any;
  handleChange?: any;
  handleSave?: any;
}

export function SiteDetail(props: SiteDetailProps) {
  const [selectedTab, setSelectedTab] = useState<string>("general");

  return props.item ? (
    <div className="siteDetailContainer">
      <div className="buttonTabContainer">
        <div
          className={"buttonTab " + (selectedTab === "general" ? "buttonTabActive" : "")}
          onClick={() => setSelectedTab("general")}
        >
          Général
        </div>
        <div
          className={"buttonTab " + (selectedTab === "activities" ? "buttonTabActive" : "")}
          onClick={() => setSelectedTab("activities")}
        >
          Activités
        </div>
        <div
          className={"buttonTab " + (selectedTab === "dechets" ? "buttonTabActive" : "")}
          onClick={() => setSelectedTab("dechets")}
        >
          Déchets
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {selectedTab === "general" ? (
          <SiteDetail_ContainerGeneral
            item={props.item}
            handleChange={props.handleChange}
            handleSave={props.handleSave}
          />
        ) : (
          <></>
        )}
        {selectedTab === "activities" ? (
          <SiteDetail_Activity
            item={props.item}
          />
        ) : (
          <></>
        )}
        {selectedTab === "dechets" ? (
          <SiteDetail_Waste item={props.item}
           />
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
